import React, { useContext, useRef, useState } from "react";
import { useFetchProductsByCategory } from "../../componentes-globales/Hooks/useFetchProductsByCategory";
import ProductsList from "../../componentes-globales/ProductsList/ProductsList";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import './PaginaDeProducto.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faGear } from "@fortawesome/free-solid-svg-icons";
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
import DOMPurify from "dompurify";
import estrella from "../../assets/images/estrella.png";
import Breadcrumbs from "../../componentes-globales/Breadcrumbs/Breabcrumbs";import useClickOutside from "../../utils/hooks/useClickOutside";
import { AlbaContext } from "../../context/Context";
import { useToken } from "../../auth/useToken";
;

const PaginaProducto = () => {
    // FETCH DESCRIPTION FOR CATEGORY
   const location = useLocation();
   const product = location.state; 
    console.log(product)
   const getMarkup = (data) => ({
        __html: DOMPurify.sanitize(data, {USE_PROFILES: { html: true }})
    });
    const stars=['','','','',''];
    //const breacrumbPath = `${category} / `
    const navigate = useNavigate();
    const [showReviewForm, setShowReviewForm]=useState(false);
    console.log(showReviewForm,'review form')
    const handleOpenReview = () =>{
        setShowReviewForm(prev => !prev);
    }
    const { cart, setCart } = useContext(AlbaContext);
    const quantity = 1;
    const newItem = {
        producto_id:product.producto_id,
        nombre:product.nombre,
        disponibilidad:product.disponibilidad,
        categoria:product.categoria,
        quantity: quantity,
        pricePerproduct: product.precio*quantity,
        type: product?.type || null,
        precio: product.precio,
        descripcion: product.descripcion,
        imagen: product.imagen,
        kilos: product.kilos,
    }

    const handleAddToCart = () => {
        setCart(prev => [
        ...prev, 
        newItem
        ])
        setCart(prev => [
        ...new Map(prev.map(item => [item.producto_id, item])).values()
        ])
        navigate('/carrito');
       
    }
    console.log(cart, 'cart');
    // const [token] = useToken();
    // const handleClickCompraRecurrente = () =>{
    //      if (token) navigate('/confirmar-pedido')
    //      else navigate("/intranet" );
    //      setCart(prev => [
    //         ...prev, 
    //         newItem
    //         ])
    //         setCart(prev => [
    //         ...new Map(prev.map(item => [item.producto_id, item])).values()
    //         ])
    //   };
    return (
       <div className="category-page">
             <Breadcrumbs category={product?.categoria} />
            <div className="contenido-category">
                <section className="image-container">
                        <picture className="img-product">
                            <img src={`../${product.imagen}`} alt={product.nombre} width="100%" />
                        </picture>
                        <div className="info-Review">
                            <p className='review-stars'>
                        {
                            stars.map((item) => {
                                return(
                                    <span className="stars"> 
                                                <picture>
                                                    <img src={estrella} alt='item' key={item} width='20'/>
                                                </picture>
                                 </span> 
                            )}
                        )}
                        </p>
                        <ButtonLink label={showReviewForm ? 'Ocultar formulario' : "Darnos tu opinión"} isEnabled color='white' backgroundColor='#38b44a' onClick={handleOpenReview}/>
                        {/* < className="review" role="button" onClick={handleOpenReview}>Darnos tu opinión</p>
                        { */}
                            { showReviewForm && (
                                <div className="action-box">
                                    <form method='' action='' id='review-form'>
                                        <div className="alert">
                                            {/* el texto de la base de datos */}
                                            <p>Si ya estas registrado, por favor introduzca su correo electrónico y la contraseña.</p>
                                            <p>Si no estas registrado haga <span onClick={()=>{navigate('/registrar')}} className='haz-click'>click aqui.</span></p>
                                        </div>
                                        <div className="form-group">
                                            <input type="email "name="usuario" size="25" maxLength="50" className="form-control" placeholder="E-mail" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="contrasena" id="contrasena" size="25" maxLength="50" className="form-control" placeholder="Contraseña" required="" />
                                        </div>
                                        <div className="form-group">
                                            <a href="/">Recordar contraseña</a>
                                        </div>
                                        <label>Valoracion del producto:</label>
                                        <p className='clasification'>
                                                {/* <input id="radio1" name="radioEs" type="checkbox" value="5" checked="checked" />
                                                <label className="lradio1" for="radio1">★</label>
                                                <input id="radio2" name="radioEs" type="checkbox" value="4"   />
                                                <label  for="radio2">★</label>
                                                <input id="radio3" name="radioEs" type="checkbox" value="3"   />
                                                <label  for="radio3">★</label>
                                                <input id="radio4" name="radioEs" type="checkbox" value="4"   />
                                                <label  for="radio4">★</label>
                                                <input id="radio5" name="radioEs" type="checkbox" value="5"   />
                                                <label  for="radio5">★</label> */}
                                                {
                                                    stars.map((item) => {
                                                        return(
                                                            <span className="stars"> 
                                                                        <picture>
                                                                            <img src={estrella} alt='item' key={item} width='20'/>
                                                                        </picture>
                                                        </span> 
                                                         )}
                                                )}
                                        </p>
                                        <label> Añadir comentario (Opcional) </label>
                                        <div className="seccion-comentarios">
                                            <div className="form-group">
                                                <label>Nick (Alias):</label>
                                                <input type ="text" name="nick" id="nick" maxLength="50" className="form-control" placeholder="Alias"/>
                                            </div>
                                            <div className="form-group">
                                                <label>Comentario:</label>
                                                <textarea className="form-control" name="comentario" id="comentario" maxLength="500" type="text" placeholder="Escribe tu comentario"></textarea>
                                            </div>
                                        </div> 
                                        <div>
                                                <p>CAPTCHA</p>
                                        </div>
                                        <p className="btn-add-review">
                                            <ButtonLink label="Añadir" isEnabled color='white' backgroundColor='#38b44a' onClick={() => {}}/>
                                        </p>
                                    </form>
                                </div>
                            )}
                     
                        
                    </div>   
                </section>
                <section className="description-product">
                    <div className="info-product">
                        <h1>{product.nombre}</h1>
                        <div dangerouslySetInnerHTML={getMarkup(product?.descripcion)}> 
                        </div> 
                        <div className="tipo-compra">
                            <div className="info-precio compra-unica">
                                <h3>Compra unica</h3>
                                <p className="precio-producto">{product.precio}€ - Precio Caja</p>
                                <p>{` ${(product.precio / Number(product.kilos)).toFixed(2)} € - el kilo `}</p>
                                <p>IVA y transporte Incluido para península</p>
                                <p className="btn-add-to-cart"><ButtonLink label={<span className="cart-icon" ><FontAwesomeIcon icon={faCartShopping}/> <span>Añadir al carro</span> </span>} isEnabled color='white' backgroundColor='#38b44a' onClick={handleAddToCart}/></p>
                            </div>
                            <div className="info-precio compra-recurrente">
                                <h3>Compra recurrente</h3>
                                <p className="precio-producto">28€ - Precio Caja</p>
                                <p>{` ${(28/ Number(product.kilos)).toFixed(2)} € - el kilo `}</p>
                                <p>IVA y transporte Incluido para península</p>
                                <label htmlFor="entrega_pedido">Entrega cada:</label>
                                    <select className="entrega-pedido" id='entrega_pedido'>
                                        <option value="dos-semanas">2 semanas</option>
                                        <option value="tres-semanas">3 semanas</option>
                                        <option value="un-mes">1 mes</option>
                                    </select>
                                <p className="btn-add-to-cart"><ButtonLink label={<span className="cart-icon" ><FontAwesomeIcon icon={faGear}/> <span>Configurar ahora</span> </span>} isEnabled color='white' backgroundColor='#38b44a' onClick={((handleAddToCart))}/></p>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="info-Review">
                        <p className='review-stars'>
                        {
                            stars.map((item) => {
                                return(
                                    <span className="stars"> 
                                                <picture>
                                                    <img src={estrella} alt='item' key={item} width='20'/>
                                                </picture>
                                 </span> 
                            )}
                        )}
                        </p>
                        <p className="review" role="button" onClick={handleOpenReview}>Darnos tu opinión</p>
                        {
                            showReviewForm && (
                                <div className="action-box">
                                    <form method='' action='' id='review-form'>
                                        <div className="alert"> */}
                                            {/* el texto de la base de datos */}
                                            {/* <p>Si ya estas registrado, por favor introduzca su correo electrónico y la contraseña.</p>
                                            <p>Si no estas registrado haga <span onClick={()=>{navigate('/registrar')}} className='haz-click'>click aqui.</span></p>
                                        </div>
                                        <div className="form-group">
                                            <input type="email "name="usuario" size="25" maxLength="50" className="form-control" placeholder="E-mail" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="contrasena" id="contrasena" size="25" maxLength="50" className="form-control" placeholder="Contraseña" required="" />
                                        </div>
                                        <div className="form-group">
                                            <a href="/">Recordar contraseña</a>
                                        </div>
                                        <label>Valoracion del producto:</label>
                                        <p className='clasification'> */}
                                                {/* FARA  <input id="radio1" name="radioEs" type="checkbox" value="5" checked="checked" />
                                                <label className="lradio1" for="radio1">★</label>
                                                <input id="radio2" name="radioEs" type="checkbox" value="4"   />
                                                <label  for="radio2">★</label>
                                                <input id="radio3" name="radioEs" type="checkbox" value="3"   />
                                                <label  for="radio3">★</label>
                                                <input id="radio4" name="radioEs" type="checkbox" value="4"   />
                                                <label  for="radio4">★</label>
                                                <input id="radio5" name="radioEs" type="checkbox" value="5"   />
                                                <label  for="radio5">★</label> */}
                                                {/* {
                                                    stars.map((item) => {
                                                        return(
                                                            <span className="stars"> 
                                                                        <picture>
                                                                            <img src={estrella} alt='item' key={item} width='20'/>
                                                                        </picture>
                                                        </span> 
                                                         )}
                                                )}
                                        </p>
                                        <label> Añadir comentario (Opcional) </label>
                                        <div className="seccion-comentarios">
                                            <div className="form-group">
                                                <label>Nick (Alias):</label>
                                                <input type ="text" name="nick" id="nick" maxLength="50" className="form-control" placeholder="Alias"/>
                                            </div>
                                            <div className="form-group">
                                                <label>Comentario:</label>
                                                <textarea className="form-control" name="comentario" id="comentario" maxLength="500" type="text" placeholder="Escribe tu comentario"></textarea>
                                            </div>
                                        </div> 
                                        <div>
                                                <p>CAPTCHA</p>
                                        </div>
                                        <p className="btn-add-review">
                                            <ButtonLink label="Añadir" isEnabled color='white' backgroundColor='#38b44a' onClick={() => {}}/>
                                        </p>
                                    </form>
                                </div>
                            )}
                     
                        
                    </div>         */}
                </section>
            </div>
        </div>
    )
}

export default PaginaProducto;
