import React, {useState, useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import './DatosPersonales.css';
import ButtonLink from "../../../../componentes-globales/ButtonLink/ButtonLink";
import ButtonBack from "../../../../componentes-globales/ButtonBack/ButtonBack";
import { AlbaContext } from "../../../../context/Context";
import { useToken } from "../../../../auth/useToken";
import axios from "axios";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const DatosPersonales = () =>{
    const {customer, contactInfo, setContactInfo, setCustomer} = useContext(AlbaContext);
    console.log(customer,'customer')
    console.log(contactInfo,'contactInfo')
    const navigate = useNavigate();
    const [token] = useToken();

    const handleSubmitInfoCustomer = async (ev) =>{
         ev.preventDefault();

        const customerBody = {
            customers_gender:customer.customers_gender===null ? '' : customer.customers_gender,
            customers_firstname:customer.customers_firstname, 
            customers_lastname:customer.customers_lastname,
            customers_email_address:customer.customers_email_address,
            customers_telephone:customer.customers_telephone===null ? '' : customer.customers_telephone,
            customers_movil:Number(customer.customers_movil),
            customers_fax:customer.customers_fax===null ? '' : customer.customers_fax,
            customers_newsletter:customer.customers_newsletter, // 0 if not checked, 1 if checke
         }
         try {
            const response = await axios.put(process.env.REACT_APP_BASEURL+'/customers', customerBody, { 
                headers: { 'Authorization': `Bearer ${token}` },
            }); 

            console.log(response.data,'response data')
            // setCustomer(response.data)
          }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
          alert('Su datos han sido modificados correctamente');
         navigate("/mi-cuenta" );
       // setContactInfo('');
    }

    return(
        <div className="container-datos">
            <h2>Datos personales</h2>
            <form method="POST" onSubmit={handleSubmitInfoCustomer}>
                <div className="mis-datos">
                    <div className="datos-perfil">
                        <label htmlFor="intro-user">Nombre:</label> 
                        <input type="text" id='intro-user-datosP' name='customers_firstname' placeholder='Nombre' pattern="[A-Za-z]{3,25}"  title="Letras. Tamaño mínimo: 3. Tamaño máximo: 15"  required value={customer && customer?.customers_firstname ? customer.customers_firstname : ''} onChange={(ev) =>
                            setCustomer(prev=>({...prev,customers_firstname: ev.target.value }))}/>
                    </div>
                    <div className="datos-perfil">
                            <label htmlFor="intro-user">Apellidos:</label> 
                            <input type="text" id='intro-apellidos-datosP' name='customers_lastname' placeholder='Apellidos' pattern="[A-Za-z]{3,50}"  title="Letras y números. Tamaño mínimo: 3. Tamaño máximo: 15"  required value={customer && customer?.customers_lastname ? customer.customers_lastname: ''} onChange={(ev) =>  setCustomer(prev=>({...prev, customers_lastname: ev.target.value })) }/>
                        {/* <ButtonLink label='Cambiar' type='submit'color='#e97625' backgroundColor='white'/>  */}
                    </div>

                    <div className="datos-perfil">
                            <label htmlFor="intro-email-datosP">Correo electronico:</label>
                            <input type="email" id='intro-email-datosP' name='customers_email_address' placeholder='Email'  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"required  value={customer && customer?.customers_email_address ? customer.customers_email_address : ''} onChange={(ev) =>  setCustomer(prev=>({...prev, customers_email_address: ev.target.value }))}/>
                        {/* <ButtonLink label='Cambiar' type='submit'color='#e97625' backgroundColor='white'/>  */}
                        
                    </div>
                    <div className="datos-perfil">
                            <label htmlFor="intro-phone">Teléfono:</label>
                            <input type='tel' name='customers_movil' id='intro-phone' value= {customer && customer?.customers_movil ? customer.customers_movil: contactInfo.phone ? contactInfo.phone : ''} onChange ={(ev) => setCustomer(prev=>({...prev, customers_movil: ev.target.value }))}required/> 
                            {/* <p>{contactInfo && contactInfo.phone ? contactInfo.phone: ''}</p>*/} 
                    </div>
                     <div className="datos-perfil newsletter newsletter-datos " >
                            <label htmlFor="checkbox-datos"> Newsletter </label>
                            <input type="checkbox" name='checkbox' id='checkbox-datos' checked= {customer && customer?.customers_newsletter ? Boolean(Number(customer.customers_newsletter)): false} onChange ={(ev) => setCustomer(prev=>({...prev, customers_newsletter: String(Number(ev.target.checked)) }))} />
                    </div> 
                    <div>
                        <ButtonLink label='Cambiar' type='submit' isEnabled color='white' backgroundColor='#EA7525'/>
                    </div> 
                 </div>
             </form>
            {/* <ButtonBack /> */}
            <div style={{marginTop:"30px"}}>
                <ButtonBack  onClick={()=>{navigate('/mi-cuenta')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525' />
            </div>
        </div>
    );
}
export default DatosPersonales;