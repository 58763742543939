import DOMPurify from 'dompurify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { adaptPhpUrl } from '../../utils/utils';
import './EnlacesInteres.css';

const EnlacesSection = ( { enlaces }) => {

    return (
        <>
        {
            enlaces?.length > 1 ? (
                <section className="panel-primary">
                    <ul>
                        {
                        enlaces?.map(link => {
                            return (
                            <li key ={link.id}>
                                <Link to={adaptPhpUrl(link.url)} >
                                    {link.text}
                                </Link>
                            </li>
                            )
                        })}
                    </ul>
                </section>
            ) : null
        }
        </>
    )
}

function adaptDbUrls(html) {
    let newHtml = html;
    if (html.indexOf('href=')) {
        if (html.indexOf('href="/')===-1) {
            newHtml.replaceAll('href="', 'href="/');
        }  
        newHtml = html.replaceAll('.php', '');
    }
    return newHtml;
}


const EnlacesInteres = ({ keyword, title, text, links }) => {

    const enlaces = links?.length && keyword?.length && links?.filter(item => item?.keyword===keyword);
    const location = useLocation();
    const pathname = { location };
    console.log(pathname)
    adaptDbUrls(text);
/* 
    if (text) {
        DOMPurify.addHook("afterSanitizeElements", function (node) {
            // Attach safe `rel` values to all elements that contain an `href`,
            // i.e. all anchors that are links.
            console.log(node)
    
            if ( node && node?.hasAttribute('href')) {
              node?.setAttribute("rel", "noopener noreferrer");
              const nodeUrl = node?.getAttribute('href');
              console.log(nodeUrl)
              const newUrl = adaptPhpUrl(nodeUrl);
              console.log(newUrl);
            }
            return node;
        })
    }; */

    const getMarkup = (data) => ({
        __html: DOMPurify.sanitize(
            data, 
            {
                USE_PROFILES: { html: true },
                FORBID_ATTR: ['class']
            }
        )
    });

    return (
        <div className="panel">
            <EnlacesSection enlaces={enlaces} />
            <section className='panel-secondary'>
                <h2>
                    {title}
                </h2>
                <div className={location?.pathname.slice(1)} dangerouslySetInnerHTML={getMarkup(adaptDbUrls(text))}>
                </div>
            </section>
        </div>
    )
}

export default EnlacesInteres;
