import React, {useEffect, useState} from "react";
import './MasProductos.css';
import ProductSlider from "./componentes/ProductSlider";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchProductsByCategory } from "../../../../componentes-globales/Hooks/useFetchProductsByCategory";
import ProductsList from "../../../../componentes-globales/ProductsList/ProductsList";
import { useFetchProducts } from "../../../../componentes-globales/Hooks/useFetchProducts";


const MasProductos = () => {

    const navigate = useNavigate();
    const handleClickProduct = () =>{
        navigate("/producto" );
    };
    
    const urlDestacados = process.env.REACT_APP_BASEURL+'/productos/destacados';
    const [destacados, setDestacados] = useState([]);
    const fetchDestacados = async () =>{
        try {
            const response = await fetch(urlDestacados);
            const data = await response.json();
            setDestacados(data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(()=> {
        fetchDestacados()
    },[])
    console.log(destacados)

    const { products, errorProducts, isLoadingProducts } = useFetchProducts();
    if (isLoadingProducts) {
        return <div>Cargando...</div>
    }
    const productsTres= products && products?.slice(0,3);
  
    return (
        <div className="products">
            <h2>También te puede gustar:</h2>
            <div className="carusel-productos">
                    <ProductsList products= {productsTres}/>
                  {/* <ProductSlider> */}
                     {/* { destacados.map(producto => (
                        <article className="producto" key={producto.producto_id} onClick={handleClickProduct}>
                             <picture className="img-carusel">
                                <img src={`../${producto.imagen}`}  alt={producto.nombre} width="100%" />
                             </picture> 
                           <h3>{producto.nombre}</h3> 
                        </article>
                    )) }  */}
                {/* </ProductSlider> */}
            </div>
        </div>
    )
}
export default MasProductos;