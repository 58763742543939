import { useState, useEffect } from "react";

export const useFetchProductosBandejas = () => {

  const [productos, setProductos] = useState(null);
  const [isLoadingProductos, setIsLoadingProductos] = useState(false);
  const [errorProductos, setErrorProductos] = useState(null);
  const productosUrl = process.env.REACT_APP_BASEURL + '/productos/bandejas/list';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProductos(true);
      try {
        const response = await fetch(productosUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingProductos(false);
        setErrorProductos(null);
        setProductos(json);
      } catch (error) {
        setErrorProductos(`${error} Could not Fetch Data `);
        setIsLoadingProductos(false);
      }
    };
    fetchData();
  }, [productosUrl]);

  return { productos, isLoadingProductos, errorProductos };
};
