import React,  { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlbaContext } from "../../../../context/Context";
import ButtonLink from "../../../../componentes-globales/ButtonLink/ButtonLink";
import './ResumenCompras.css';
import { useToken } from "../../../../auth/useToken";
import ButtonBack from "../../../../componentes-globales/ButtonBack/ButtonBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const ResumenCompras = () => {
    const { pathname } = useLocation();
    const { total } = useContext(AlbaContext);
    const navigate = useNavigate();
    const [token] = useToken() 
    const handleClickRealizarPedido = (ev) =>{
       // ev.preventDefault();
        if (token) navigate('/mi-cuenta')
        else navigate("/intranet" );
     };
   
    return(
        <div  className={pathname==='/carrito'? 'ordered-shopping' : "ordered-shopping-cost"}>
            <div className='resumen-compras'>
                <h2>Resumen del pedido</h2>
                <p className="subtotal">
                    <span>Subtotal</span>
                    <span><span>{total}</span>
                    <span>€</span></span></p>
                <p className="total"><span className="total-impuestos">
                                        <span>Total</span>
                                        <span>(incl.impuesto y envío)</span>
                                    </span>
                                    <span>
                                        <span>{total}</span>
                                        <span>€</span>
                                    </span>
                </p>

             <ButtonLink  onClick={handleClickRealizarPedido} isEnabled label="Realizar pedido"  type='button' color='#EA7525' backgroundColor='transparent' /> 
            </div>
        </div>
    );

}
export default ResumenCompras;
