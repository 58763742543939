import React, {useEffect, useState, useContext} from "react";
import axios from "axios";
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
import { useNavigate } from "react-router-dom";
import './Login.css';
import { AlbaContext } from '../../context/Context';
import { useToken } from "../../auth/useToken";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import LoginGoogle from "../LoginGoogle/LoginGoogle";
import DecodeJWT from "../../utils/DecodeJWT";
// import { FacebookProvider, LoginButton } from 'react-facebook';
import FacebookLogin from "@greatsumini/react-facebook-login";

const Login = () => {
    const { userAddresses, setUserAddresses, customer, setCustomer, isGoogleClient, setIsGoogleClient} = useContext(AlbaContext)
    const [token, setToken] = useToken();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [getAddressSuccess, setGetAddressSuccess] = useState(false);
    const [getCustomerSuccess, setGetCustomerSuccess] = useState(false);
    const [errorMessageLogin, setErrorMessageLogin]= useState('')
    const [errorMessageDirecciones, setErrorMessageDirecciones]= useState('')
    const [errorMessageCustomers, setErrorMessageCustomers]= useState('')
    const [showPwd, setShowPwd] = useState(false);
    const [passErrorMessage, setPassErrorMessage] = useState(false);
    
    const navigate = useNavigate(); 
    /*----------Login con email y contraseña--------------*/
     const handleLogIn = async (e) => {

         e.preventDefault();
         const urlLogin = process.env.REACT_APP_BASEURL+'/auth/login'
         try {
          const response = await axios.post(urlLogin, {
            email, 
            password
          }, {
            headers: { 'Content-Type': 'application/json' },
          } 
        );

          setToken(response.data.token)
          sessionStorage.setItem('userId', JSON.stringify(response.data.user.customers_id))
          sessionStorage.setItem('token', response.data.token)
          setCustomer({
            customers_id: response.data.user.customers_id,
            customers_firstname: response.data.user.customers_firstname,
            customers_lastname: response.data.user.customers_lastname,
            customers_email_address: response.data.user.customers_email_address,
            customers_movil: response.data.user.customers_movil,
          }) 

          setLoginSuccess(true); 
        }
        catch (error) {
            if (error.response) {
              console.log(error.response?.statusText);
              if(error.response?.data?.message) setErrorMessageLogin(error.response?.data?.message);
            } else if (error.request) {
              setErrorMessageLogin("network error")
              console.log(errorMessageLogin);
            } else {
              console.log(error);
              setErrorMessageLogin("api/auth/login failed")
            }
        }
    
        setEmail('');
        setPassword('');
    }; 
 
    /*---------------Login con Google-----------*/
    const handleErrorGoogle = (error) =>{
      console.log(error);
    } 

    const urlGoogleLogin = process.env.REACT_APP_BASEURL+'/auth/google';
    const handleSuccessGoogle = (codeResponse) =>{
      console.log(codeResponse);
      if (codeResponse.credential){
        const { payload } = DecodeJWT(codeResponse.credential);
        console.log('payload credencial', payload);

        const fetchLogin = async () =>{
          
          try{
            
            const response= await axios.post(urlGoogleLogin, {token: codeResponse.credential, platform: 'WEB'});
            console.log(response.data);
            setIsGoogleClient(true);
            setToken(response.data.token)
            sessionStorage.setItem('userId', JSON.stringify(response.data.user.customers_id))
            sessionStorage.setItem('token', response.data.token)
            setCustomer({
              customers_id: response.data.user.customers_id,
              customers_firstname: response.data.user.customers_firstname,
              customers_lastname: response.data.user.customers_lastname,
              customers_email_address: response.data.user.customers_email_address,
              customers_movil: response.data.user.customers_movil,
            })
          }

           catch (error) {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else {
              console.log(error);
            }
          }

       };
  
      fetchLogin();
    }
  };


    const urlDirrecciones = process.env.REACT_APP_BASEURL+'/direcciones';
    // console.log(urlDirrecciones, 'urldirecciones');
    const getUserAddress = async () => {
      try {
        const response = await axios.get(urlDirrecciones, { 
          headers: { 'Authorization': `Bearer ${token}` },
        } 
      );
      console.log(response.data, 'getUserAdd')
        setUserAddresses(response.data)
        setGetAddressSuccess(true);
      }
      catch (error) {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          setErrorMessageDirecciones("network error")
          console.log(errorMessageDirecciones);
        } else {
          console.log(error);
          setErrorMessageDirecciones("api/direcciones failed")
        }
      }
    } 

    const urlCustomers = `${window.location.protocol}//${window.location.hostname}:4040/api/customers`;
    const getCustomer = async () => {
        try {
          const response = await axios.get(urlCustomers, { 
            headers: { 'Authorization': `Bearer ${token}` },
          })
            setCustomer(response.data)
            setGetCustomerSuccess(true)
        } catch (error) {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            setErrorMessageCustomers("network error")
            console.log(errorMessageCustomers);
          } else {
            console.log(error);
            setErrorMessageCustomers("api/customers failed")
          }
        }
    }


    useEffect(()=> {
      if (loginSuccess || isGoogleClient) {
        getCustomer();
      }
      else if (errorMessageLogin) {
        console.log(errorMessageLogin)
        if (errorMessageLogin === 'InvalidEmailPassword') {
          setPassErrorMessage('Oops... no podemos identificarte, por favor revise tus credenciales')
        } else if (errorMessageLogin) {
          setPassErrorMessage('Ha ocurrido un problema en el server, por favor intentalo más tarde')
        }
      } 
    },[loginSuccess, isGoogleClient, errorMessageLogin])

    useEffect(()=> {
        if (loginSuccess || isGoogleClient) {
          getUserAddress();
        }
    }, [loginSuccess, isGoogleClient])

    if (getAddressSuccess) {
      if (userAddresses.length > 0) navigate('/mi-cuenta')
      else if (userAddresses.length === 0) navigate('/registrar-direccion') 
    }

 return(
    <div className="form-login">
        <h2 className="paso-login">Iniciar Sesión</h2>
        <div className="contenido-form">
          <div className="col-contenido login">
            <p className="acceder">Accede con:</p>
            <div className="container-google">
              <GoogleLogin useOneTap onSuccess ={handleSuccessGoogle} onError={handleErrorGoogle} />
            </div>
            <p className="acceder">O con un correo electrónico:</p>
            <form className="formulario" method="post" onSubmit={handleLogIn}>
              <div className="field-form">
                  <input type="text" name="email" value={email} id="intro-user" placeholder="Email" /* pattern="[A-Za-z0-9]{3,15}" */  title="Letras y números. Tamaño mínimo: 3. Tamaño máximo: 15" required  onChange= {(e) => {setEmail(e.target.value)}}/>
              </div>
              <div className="field-form">
                  <input type={showPwd ? "text": "password"} name="password" id="intro-password" value={password} placeholder="Contraseña " pattern=".{6,}" title="Seis o más caracteres" required  onChange= {(e) => {setPassword(e.target.value)}}/>
                  <div className="position-absolute-pwd-icon" onClick = {() => setShowPwd((current) => !current)}>
                    {showPwd ? <span className="icon-eye"></span> : <span className="icon-eye-blocked"></span>}
                  </div>
              </div>
              {
                passErrorMessage && <div className="error-message">{passErrorMessage}</div>
              }
              {/* <ButtonLink  label="Entrar" isEnabled  type='submit' color=' #e97625' backgroundColor='white' /> */}
              <div className="field-form">
                  <ButtonLink  label="Acceder" isEnabled  type='submit' color=' white' backgroundColor='#38b44a' />
              </div>
            </form>
            <p className="forgot-password"><span onClick={()=> navigate("/")}>¿Olvidaste tu contraseña?</span></p>
            
         {/*<p className="redes-line"></p>
        <div className="login-redes form-registrar-elem">
            <div className="container-google">
              <GoogleLogin useOneTap onSuccess ={handleSuccessGoogle} onError={handleErrorGoogle} />
              </div> 
        </div>*/}
        </div>
        <div className="col-contenido registrar" onClick={()=> navigate("/registrar")}>
          <h2>Paso 1 <span>Registro</span></h2>
          <p>¿Por que registrarse?</p>
          <p>-Para estar informado de cuando empieza la campaña de la naranjas y de todos nuestros productos, y no perder ni un dia en saborear las naranjas de Valencia.</p>
          <p>-Y mantenerse informado de las ofertas y promociones de Hort Alba Garcés.</p>
        </div>
        {/* <p className="forgot-password"><span onClick={()=> navigate("/")}>¿Olvidaste tu contraseña?</span></p> 
        <div className="registrar"><span onClick={()=> navigate("/registrar")}>¿No tienes cuenta? Regístrese</span></div>*/}
    </div>
    </div>

 );
}
export default Login;
