import React, { useContext } from "react";
import axios from 'axios'
import ResumenCompras from "../Carrito/componentes/ResumenCompras/ResumenCompras";
import { AlbaContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
import { useToken } from "../../auth/useToken";
import './ConfirmarPedido.css';


/*-------------MAS COMPRAS -------------------*/
export const MasCompras = () => {
    const navigate = useNavigate();
    const handleMasCompras = () =>{
        navigate('/productos');
    }
    return(
       <p className="otros-productos" onClick={handleMasCompras}>Tal vez quieres algo mas antes de realizar el pedido<span >...</span>
        </p>
    );
}


const ConfirmarPedido = () =>{
    const { setCart, cart, userAddresses, setIdPedido } = useContext(AlbaContext);
    const [token] = useToken()
    const navigate = useNavigate();  
    const handleClickEdit = () =>{
        navigate("/direcciones" );
    };
    const handleSubmitOtherAdress = () =>{
        navigate('/registrar-direccion');
    }
  
    console.log(cart)

    const myDefaultAddress = userAddresses[0] // GETING FIRST ADDRESS, REPLACE IF NECESSARY
    const address_book_id = userAddresses.length>0 && myDefaultAddress?.address_book_id!=='' ? myDefaultAddress.address_book_id : ''

    const myPedido = cart.map(cartItem => {
        let cartObject = {}
        cartObject.productoId = cartItem.producto_id;
        cartObject.cantidad = cartItem.quantity;
        cartObject.bandejaId1 = cartItem.bandejaId1;
        cartObject.bandejaId2 = cartItem.bandejaId2;
        cartObject.bandejaId3 = cartItem.bandejaId3;
        return cartObject
        })

    const handleSubmitDetails = async (e) =>{
        e.preventDefault()
        const pedidoBody = {
          carro: myPedido,
          address_book_id
         }

         try {
            const response = await axios.post(process.env.REACT_APP_BASEURL+'/pedidos', pedidoBody, {
              headers: { 'Authorization': `Bearer ${token}` }
            } 
          );
          // setCart([])
          setIdPedido(response.data.pedidoId);
          navigate(`/metodo-de-pago/${response.data.pedidoId}`);
          // navigate(`/detalles-pedido/${response.data.pedidoId}`);

          }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
        
    }

    if (userAddresses.length<1) return null;
    

    return (
        userAddresses.length>=1 && (
        <div className='contenedor-confirmarPedido'>
            {/* <div className="breadcrumb-payment">
                <span className="envio-payment">1</span><span>Envio</span><span className="guion-payment"></span><span className="envio-payment">2</span><span>Método del pago</span>
            </div> */}
            <h2 className="paso-confirmar">Paso 2</h2>
            <div className="modoConfirmar"> 
                        <h2>Paso 2 <span>Realizar pedido</span></h2>
            </div>
            <div className="confirmar-details">
                    <div className="shipping-address">
                        <h2>Dirección de envío</h2>
                        <ul>
                            <li className='info-adress'>
                             <div>
                                <p>{`${myDefaultAddress?.entry_firstname} ${myDefaultAddress?.entry_lastname}`}</p>
                                {myDefaultAddress?.entry_company && <p>{myDefaultAddress?.entry_company}</p>} 
                                <p>{myDefaultAddress?.entry_street_address}</p>
                                <p>{`${myDefaultAddress?.entry_city} ${myDefaultAddress?.entry_postcode}`}</p>
                                <p>{`${myDefaultAddress?.entry_suburb} ${myDefaultAddress?.entry_country_id === 1 && 'España'} `}</p>
                             </div>
                             <span><ButtonLink  label="Editar" isEnabled type='button' color='white' backgroundColor='#38b44a' onClick={handleClickEdit} /></span>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="invoice-address">
                        <h2>Dirección de facturación</h2>
                        <input type='checkbox' id='invoice' name='invoice'/>
                        <label htmlFor="invoice">Necesito factura</label>
                        <div className="invoice-address-block">
                            <div className="div-invoice-address">
                                <input type='radio' id='input-invoice-address' name='input-invoice-address' value='Mi direccíon de facturacíon y de envío son la misma'/>
                                <label htmlFor="input-invoice-address">Mi direccíon de facturacíon y de envío son la misma</label>
                            </div>
                        </div>
                        <div className="invoice-address-form">
                            <div className="div-invoice-address-form">
                                <input type='radio' id='input-invoice-address-form' name='input-invoice-address' value='other address'/>
                                <label htmlFor="input-invoice-address-form">Otra direccíon</label>
                                <div className="other-address">   
                                    
                                    <div className="buttons">
                                    <ButtonLink  label="Actualizar"  isEnabled type='submit' color='orange' backgroundColor='transparent' onClick={handleSubmitOtherAdress} />
                                    </div> 
                                </div> 
                            </div>  
                        </div>
                    </div> */}   
                <ResumenCompras />
            </div>

            <MasCompras />
             {/* <form onSubmit={handleSubmitDetails}> */}
                <ButtonLink  label="Realizar pedido" isEnabled  type='submit' color='white' backgroundColor='#EA7525' onClick={handleSubmitDetails} />
            {/* </form>  */}

        </div> )    
    );
}
export default ConfirmarPedido;
