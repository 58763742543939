import { useState, useEffect } from "react";

export const useFetchMenusEnlacesDeInteres = () => {

  const [dataMenusEnlaces, setDataMenusEnlaces] = useState(null);
  const [isLoadingDataMenusEnlaces, setIsLoadingDataMenusEnlaces] = useState(false);
  const [errorDataMenusEnlaces, setErrorDataMenusEnlaces] = useState(null);
  const menuEnlacesDeInteresUrl = `${process.env.REACT_APP_BASEURL}/menu-varios`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingDataMenusEnlaces(true);
      try {
        const response = await fetch(menuEnlacesDeInteresUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingDataMenusEnlaces(false);
        setErrorDataMenusEnlaces(null);
        setDataMenusEnlaces(json);
      } catch (error) {
        setErrorDataMenusEnlaces(`${error} Could not Fetch Data `);
        setIsLoadingDataMenusEnlaces(false);
      }
    };
    fetchData();
  }, [menuEnlacesDeInteresUrl]);

  return { dataMenusEnlaces, isLoadingDataMenusEnlaces, errorDataMenusEnlaces };
};
