import React, { useContext } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import { PrivateRoute } from './auth/PrivateRoute';
 import MiCuenta from './pages/Paginas_de_usuario/MiCuenta/MiCuenta';
import Pedidos from './pages/Paginas_de_usuario/componentes/PedidosIntranet/Pedidos';
import Direcciones from './pages/Paginas_de_usuario/componentes/Direcciones/Direcciones';
import DatosPersonales from './pages/Paginas_de_usuario/componentes/DatosPersonales/DatosPersonale';
import { AlbaContext } from './context/Context';
import OtraDireccion from './pages/Paginas_de_usuario/componentes/Direcciones/OtraDireccion/OtraDireccion';
import DetallesPedido from './pages/Paginas_de_usuario/componentes/PedidosIntranet/Detalles_Pedido/DetallesPedido';
import Registrar from './pages/Registrar/Registrar';
import RegistrarDireccion from './pages/RegistrarDireccion/RegistrarDireccion';
import Header from './componentes-globales/Header/Header';
import Footer from './componentes-globales/Footer/Footer';
import CategoriaProducto from './pages/CategoriaProducto/CategoriaProducto';
import Factura from './pages/Paginas_de_usuario/componentes/Factura/Factura';
import Carrito from './pages/Carrito/Carrito';
import MetodoDelPago from './pages/Paginas_de_usuario/componentes/PedidosIntranet/MetodoDelPago/MetodoDelPago';
import ConfirmarPedido from './pages/ConfirmarPedido/ConfirmarPedido';
import Home from './pages/Home/Home';
import PaginaProducto from './pages/PaginaDeProducto/PaginaDeProducto';
import CajaMedida from './pages/Caja_a_medida/CajaMedida';
import Contacto from './pages/Contacto/Contacto';
import { useFetchMenus } from './componentes-globales/Hooks/useFetchMenus';
import { useGetCountries } from './componentes-globales/Hooks/useGetCountries';
import { useFetchCategorias } from './componentes-globales/Hooks/useFetchCategorias';
import { useFetchMenusEnlacesDeInteres } from './componentes-globales/Hooks/useFetchMenusEnlacesDeInteres';
import EnlacesInteres from './pages/PaginasEnlacesDeInteres/EnlacesInteres';
import { useFetchInternationalization } from './componentes-globales/Hooks/useFetchInternationalization';
import { adaptPhpUrl } from './utils/utils';

const App = () => {
  const {customer, language} = useContext(AlbaContext);

  const {dataMenus, errorDataMenus, isLoadingDataMenus} = useFetchMenus();
  const {dataCountries, errorDataCountries, isLoadingDataCountries} = useGetCountries();
  const {data: categorias, errorData: errorDataCategorias, isLoadingData: isLoadingDataCategorias} = useFetchCategorias();
  const {dataMenusEnlaces, errorDataMenusEnlaces, isLoadingDataMenusEnlaces} = useFetchMenusEnlacesDeInteres();
  const {dataInternationalization, errorDataInternationalization, isLoadingDataInternationalization} = useFetchInternationalization(language);

  console.log(dataInternationalization, 'dataInternationalization')
  const menuProductos = dataMenus?.menus;
  const menuEnlaces = dataMenusEnlaces?.menus?.filter(link => link?.identificador!==23);

  const navMenuPrincipal = menuProductos?.filter((item) => 
    item.categoria_id < 50 // TOBE <50
  );

  const navMenuSecondary = menuProductos?.filter((item) => 
  item.categoria_id >= 50
);

  const footerMenuPrincipal = menuProductos?.filter((item) => 
  item.categoria_id <= 70 && item.categoria_id !== 5 && item.categoria_id !== 45 && item.categoria_id !== 50
  );

  const footerMenuSecondary = menuProductos?.filter((item) => 
  item.categoria_id === 80 || item.categoria_id === 100 || item.categoria_id === 130 || item.categoria_id === 150
  );

  const paginasEnlacesInteres = dataInternationalization?.textos?.filter((item) =>  item?.type === 'enlaces-interes' && item?.keyword.startsWith('index-'));
  const textoPaginasInteres = dataInternationalization?.textos?.filter((item) =>  item?.type === 'enlaces-interes' && item?.keyword === 'text1');
  const titulosPaginasInteres = dataInternationalization?.textos?.filter((item) =>  item?.type === 'enlaces-interes' && item?.keyword === 'title');

  const filteredFlags = dataCountries?.filter(flag => flag.iso === 'ES' || flag.iso === 'FR' || flag.iso === 'DE')?.map( flag => ({
    urlImage: `${flag?.iso?.toLowerCase()}.png`,
    name: flag?.iso === 'ES' ? 'Español' : flag?.iso ==='FR' ? 'Français' : flag?.iso ==='DE' ? 'Deutsch' : '',
    urlTo: flag?.iso === 'ES' ? 'https://www.naranjasalbagarces.com' : flag?.iso ==='FR' ? 'https://france.valenciasoranges.com' : flag?.iso ==='DE' ? 'https://www.valenciaorangen.com' : ''
  })) 

  const payment = {
      "merchantParameters": "eyJEU19NRVJDSEFOVF9BTU9VTlQiOiIxIiwiRFNfTUVSQ0hBTlRfT1JERVIiOiI0Mzk3NTEzOTEyIiwiRFNfTUVSQ0hBTlRfTUVSQ0hBTlRDT0RFIjoiMjk3MDA1NzIwIiwiRFNfTUVSQ0hBTlRfQ1VSUkVOQ1kiOiI5NzgiLCJEU19NRVJDSEFOVF9UUkFOU0FDVElPTlRZUEUiOiIwIiwiRFNfTUVSQ0hBTlRfVEVSTUlOQUwiOiIxIiwiRFNfTUVSQ0hBTlRfTUVSQ0hBTlRVUkwiOiJodHRwczovL2xvY2FsaG9zdC9hcGkvcGVkaWRvcy1wLzQzOTc1L3JlZHN5cy9wYWdhZG8iLCJEU19NRVJDSEFOVF9VUkxPSyI6ImludGVudDovL2NhbGxiYWNrP3BlZGlkb0lkPTQzOTc1JnN0YXR1cz1vayNJbnRlbnQ7ZW5kIiwiRFNfTUVSQ0hBTlRfVVJMS08iOiJpbnRlbnQ6Ly9jYWxsYmFjaz9wZWRpZG9JZD00Mzk3NSZzdGF0dXM9a28jSW50ZW50O2VuZCIsIkRTX01FUkNIQU5UX01FUkNIQU5UTkFNRSI6IkhvcnQgQWxiYSBHYXJjZXMiLCJEU19NRVJDSEFOVF9DT05TVU1FUkxBTkdVQUdFIjoiMDAxIn0=",
      "signature": "HLFLebfA7pXISR97anm4VKIvhYE6nVHIk5cHEJrkQ6Y=",
      "raw": {
          "DS_MERCHANT_AMOUNT": "1",
          "DS_MERCHANT_ORDER": "4397513912",
          "DS_MERCHANT_MERCHANTCODE": "297005720",
          "DS_MERCHANT_CURRENCY": "978",
          "DS_MERCHANT_TRANSACTIONTYPE": "0",
          "DS_MERCHANT_TERMINAL": "1",
          "DS_MERCHANT_MERCHANTURL": "https://localhost/api/pedidos-p/43975/redsys/pagado",
          "DS_MERCHANT_URLOK": "intent://callback?pedidoId=43975&status=ok#Intent;end",
          "DS_MERCHANT_URLKO": "intent://callback?pedidoId=43975&status=ko#Intent;end",
          "DS_MERCHANT_MERCHANTNAME": "Hort Alba Garces",
          "DS_MERCHANT_CONSUMERLANGUAGE": "001"
      }
  }

  return (
    <div className="App" >
      <Header flags={filteredFlags} categorias={categorias} menuPrincipal={navMenuPrincipal} menuSecondary={navMenuSecondary} />
     
      <Routes>
        <Route path='/' element={<Home/>} />
        {
          menuProductos && menuProductos?.map(item => {
            const category = categorias?.find(categoria => categoria?.categoria_id === item.categoria_id)?.categoria;
            if (item.categoria_id === 5) {
              return (
                <Route key={item?.categoria_id} path={`/${language==='es' ? item.url_es : item.url}`} element={<CajaMedida/>} />
              )
            }
            return (
             <Route key={item?.categoria_id} path={`/${language==='es' ? item.url_es : item.url}`} element={<CategoriaProducto category={category} /> } />
            )
          }) 
        }
        {
          language==='es' && paginasEnlacesInteres && paginasEnlacesInteres?.map(item =>  {
            const text = textoPaginasInteres?.find(pagina => pagina?.url === item?.url)?.text;
            const title = titulosPaginasInteres?.find(titulo => titulo?.url === item?.url)?.text;
            return (
             <Route key={item?.id} path={adaptPhpUrl(item?.url)} element={<EnlacesInteres keyword={item?.keyword} title={title} text={text} links={paginasEnlacesInteres} /> } />
            )}
          ) 
        }
        <Route path='/intranet' element={<Login/>} />
        <Route path='/:productName' element={<PaginaProducto />} />

        <Route path='/mi-cuenta' element={
            <PrivateRoute user={customer}>
                <MiCuenta />
            </PrivateRoute>
        }/>
        <Route path='/confirmar-pedido' element={
            <PrivateRoute user={customer}>
                <ConfirmarPedido  />
            </PrivateRoute>
        }/>
        <Route path='/datos-personales' element={
              <PrivateRoute user={customer}>
                  <DatosPersonales/>
              </PrivateRoute>
        }/>
        <Route path='/registrar-direccion' element={
              <PrivateRoute user={customer}>
                  <RegistrarDireccion  />
              </PrivateRoute>
        }/>
        <Route path='/direcciones' element={
              <PrivateRoute user={customer}>
                  <Direcciones/>
              </PrivateRoute>
        }/>
        <Route path='/otra-direccion' element={
            <PrivateRoute user={customer}>
                <OtraDireccion />
            </PrivateRoute>
        }/>
          {/* <Route path='/pagos' element={
                <PrivateRoute user={customer}>
                     <Pagos/>
                </PrivateRoute>
          }/> */}
        <Route path='/metodo-de-pago/:pedidoId' element={
            <PrivateRoute user={customer}>
                <MetodoDelPago />
            </PrivateRoute>
        }>
        </Route> 
        <Route path='/pedidos' element={
            <PrivateRoute user={customer}>
                <Pedidos/>
            </PrivateRoute>
        }/>
        <Route path='/detalles-pedido/:pedidoId' element={
            <PrivateRoute user={customer}>
                <DetallesPedido />
            </PrivateRoute>
        } >
        </Route>
            <Route path='/factura' element={
                <div className="contenido-factura"  > 
                    <PrivateRoute user={customer}>     
                       <Factura />
                    </PrivateRoute>
                </div>
                  } >
            </Route>  
        <Route path='/registrar' element={<Registrar />} />
        <Route path='/carrito' element={<Carrito  />} />
        <Route path='/contacto' element={<Contacto  />} />
      </Routes>
      <Footer flags={filteredFlags} 
        menuPrincipal={footerMenuPrincipal} 
        menuSecondary={footerMenuSecondary}
        menuEnlaces={menuEnlaces}
        />
    </div>
  );
}

export default App;
