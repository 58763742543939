import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getCategoryImageUrl } from "../../pages/CategoriaProducto/CategoriaProducto";
//  import './Breabcrumbs.css';

const Breadcrumbs= ({category}) =>{
    const location = useLocation();
    let currentLink = '';
    // console.log(category);
    const pathname = location.pathname.split('/')

    console.log(pathname);
    const crumbs = pathname.filter(crumb => crumb !== '')
        .map(crumb => {
            const urlToDisplay = crumb.indexOf('ó')!==-1 ? crumb.replace('ó', 'o') : crumb; 
            currentLink += `/${crumb}`;
            return (
                <div className="crumb" key={crumb}>
                    <Link to ={currentLink}>{urlToDisplay}</Link>
                </div>
            )
        })


    return (
        <div className="breadcrumbs">
            <div><Link to="/">Inicio</Link></div>
            {location.pathname!=='/' && <span className="linea">/</span>}
            {category && (
                <>
                    <div><Link to={`/${category}`}>{category}</Link></div>
                    <span className="linea">/</span>
                </>
            )}
            {crumbs}
        </div>
    )
}
export default Breadcrumbs;
