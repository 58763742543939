import React, { useEffect, useState } from "react";
import { useFetchProductsByCategory } from "../../componentes-globales/Hooks/useFetchProductsByCategory";
import ProductsList from "../../componentes-globales/ProductsList/ProductsList";
import './CategoriaProducto.css';
import Breadcrumbs from "../../componentes-globales/Breadcrumbs/Breabcrumbs";
import { Link, useLocation } from "react-router-dom";

const CategoriaProducto = (category) => {
    // FETCH DESCRIPTION FOR CATEGORY
    const [urlCategory, setUrlCategory] = useState('');
    const categoria = category?.category;
    console.log(categoria)
    const getCategoryImageUrl = (category) => {
        if (category.indexOf('ó')!==-1) {
            return `comprar_${category.replace('ó', 'o')}_online`;
        }
        return `comprar_${category}_online`
    }
    const {data: dataCategory, errorData: errorDataCategory, isLoadingData: isLoadingDataCategory} = useFetchProductsByCategory(category.category);

    useEffect(() => {
        if (categoria)
        setUrlCategory(getCategoryImageUrl(categoria))
    }, [categoria])

    if (errorDataCategory) {
        return <div>De momento no hay productos para mostar...</div>
    }

    if (isLoadingDataCategory) {
        return <div>Cargando...</div>
    }
    
    return (
       <div className="category-page">
            <Breadcrumbs />
            <section className="description-section">
                <div>
                    <h2>Comprar </h2>
                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed pulvinar proin gravida hendrerit. Lacus luctus accumsan tortor posuere ac ut consequat. Pretium fusce id velit ut tortor. Bibendum at varius vel pharetra vel turpis nunc eget. Commodo odio aenean sed adipiscing diam donec. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. Elit ullamcorper dignissim cras tincidunt lobortis feugiat. Leo vel fringilla est ullamcorper eget nulla. Et malesuada fames ac turpis. Tortor vitae purus faucibus ornare suspendisse. Pellentesque habitant morbi tristique senectus et netus. Tellus integer feugiat scelerisque varius morbi enim. Velit egestas dui id ornare. Nulla aliquet enim tortor at auctor. Commodo nulla facilisi nullam vehicula ipsum. Etiam dignissim diam quis enim lobortis scelerisque fermentum. Libero volutpat sed cras ornare. Sagittis purus sit amet volutpat. Quis enim lobortis scelerisque fermentum dui.</p>
                 </div>
                {
                    urlCategory && (
                        <picture className="img-product">
                        <img src={`../images/${urlCategory}.jpeg`} alt=' ' width="270" /> 
                     </picture>
                    )
                }  
            </section>
            <ProductsList products={dataCategory} />
        </div>
    )
}

export default CategoriaProducto