import axios from "axios";
import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import './Registrar.css';
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
const Registrar =()=>{
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [checkNewsletter, setCheckNewsletter]= useState(false);

    const [showPwd, setShowPwd] = useState(false);
    const [showPwd1, setShowPwd1] = useState(false);
    const [firstPass, setFirstPass] = useState("");
    const [checkPass, setCheckPass] = useState("");
    const [passError, setPassError] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [errorMessage, setErrorMessage]= useState('')

    useEffect(() => {
        setPassError(firstPass !== checkPass);
    }, [firstPass, checkPass]);

    useEffect(() => {
        console.log(passError);
    }, [passError]);

    const handleChange = (ev) => {
        if (ev.target.name === 'password') {
            setFirstPass(ev.target.value);
        }
        if (ev.target.name === 'intro-password2') {
            setCheckPass(ev.target.value);
        }
    }
    const handleNewsletter = () =>{
        setCheckNewsletter(!checkNewsletter);
    }
    const navigate = useNavigate()

    const register = async (e) =>{
       e.preventDefault()
        
        try {
            const response = await axios.post(process.env.REACT_APP_BASEURL+'/auth/registro', {
                firstName, 
                lastName, 
                email, 
                password:checkPass, 
                newsletter:checkNewsletter
            }, {
              headers: { 'Content-Type': 'application/json' },
            } 
          );
            setSignupSuccess(true)
            navigate('/')
        }
        catch (error) {
            if (error.response) {
            console.log(error.response);
            console.log("server responded");
            } else if (error.request) {
            setErrorMessage("network error")
            console.log(errorMessage);
            } else {
            console.log(error);
            setErrorMessage("Registration failed")
            }
        }
    }

    return (
        <div className="form-registrar">
            <h2>Registrar</h2>
            <div className="form-registrar-items">
                <form id="register-form" className="formulario-registrar form-registrar-elem" onSubmit={register}>
                    <div className="field-form">
                        {/* <label htmlFor="intro-user"><span className="icon-user1"></span></label> */}
                        <input type="text" id='intro-user1' name='firstName' placeholder='Nombre' pattern="[A-Z a-z]{3,25}"  title="Letras. Tamaño mínimo: 3. Tamaño máximo: 15"  required  onChange= {(e) => {setFirstName(e.target.value);}}/>
                    </div>
                    <div className="field-form">
                        {/* <label htmlFor="intro-user"><span className="icon-user1"></span></label> */}
                        <input type="text" id='intro-apellidos' name='lastName' placeholder='Apellidos' pattern="[A-Z a-z]{3,50}"  title="Letras y números. Tamaño mínimo: 3. Tamaño máximo: 15"  required onChange= {(e) => {setLastName(e.target.value);}}/>
                    </div>
                    <div className="field-form">
                        {/* <label htmlFor="intro-email"><span class="icon-mail3"></span></label> */}
                        <input type="email" id='intro-email1' name='email' placeholder='Email'  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"required onChange= {(e) => {setEmail(e.target.value);}}/>
                    </div>
                    <div className="field-form">
                        {/* <label htmlFor="intro-password"><span className="icon-locked"></span></label> */}
                        <input type={showPwd ? "text": "password"} id="password" 
                            name="password" placeholder=' Contraseña' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres" required
                            onChange={handleChange} value={firstPass} />
                        <div className="position-absolute1-pwd-icon" onClick = {() => setShowPwd((current) => !current)}>
                                    {showPwd ? <span className="icon-eye"></span> : <span className="icon-eye-blocked"></span> }  
                        </div>
                    </div>
                    
                    <div className="field-form">
                        {/* <label htmlFor="intro-password2"><span className="icon-locked"></span></label> */}
                        <input type={showPwd1 ? "text": "password"} id="intro-password2" name="intro-password2" placeholder=' Confirmar contraseña' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres"
                        onChange={handleChange} value={checkPass} required/> 
                        <div className="position-absolute1-pwd-icon" onClick = {() => setShowPwd1((current) => !current)}>
                                    {showPwd1 ? <span className="icon-eye"></span> : <span className="icon-eye-blocked"></span> }  
                        </div>
                    </div>
                     {passError ? <p className="error">Los campos contraseña no son iguales</p> : null }
                    {/* <button type='submit'className='btn-form' disabled={passError} >Registrarse </button> */}
                     <div className="newsletter" >
                            <input type="checkbox" name="checkbox" id="checkbox" checked={checkNewsletter} onChange={handleNewsletter}/>
                            <label htmlFor="checkbox"> Newsletter </label>
                    </div> 
                    <ButtonLink  label="Registrarse" isEnabled  type='submit' color='white' backgroundColor='#38b44a' />
                </form>
               {/*  <div className="login-redes form-registrar-elem">
                    <p><a href="/" className="red-facebook"><span className="icon-facebook"></span>Registrarse con Facebook</a></p>
                    <p><a href="/" className="red-gmail"> <span className="icon-gmail"></span>Registrarse con Gmail</a></p> 
                </div> */}
            </div>
        </div>
    );
}

export default Registrar;