import { useState, useEffect } from "react";

export const useFetchProductsByCategory = (category) => {

  const [data, setData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const categoryProductsUrl = `${process.env.REACT_APP_BASEURL}/productos/list/${category}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const response = await fetch(categoryProductsUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingData(false);
        setErrorData(null);
        setData(json);
      } catch (error) {
        setErrorData(`${error} Could not Fetch Data `);
        setIsLoadingData(false);
      }
    };
    fetchData();
  }, [categoryProductsUrl]);

  return { data, isLoadingData, errorData };
};
