import React, {useContext} from "react";
import { AlbaContext } from "../../../../context/Context";
import CartItem from "./CartItem";
import './Compras.css';

const Compras = () =>{
    const { cart }  = useContext(AlbaContext);

    return(
        <div className="shopping-total">
            <h2>Mi carrito</h2>
            <div className="shopping">
                { cart.map(cartItem => <CartItem cartItem={cartItem} />) }
            </div>
        </div>
    );
}
export default Compras;
