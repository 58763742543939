import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import { useNavigate, useParams } from "react-router-dom";
import { AlbaContext } from "../../../../../context/Context";
import './DetallesPedido.css';
import ButtonLink from "../../../../../componentes-globales/ButtonLink/ButtonLink";
import ButtonBack from "../../../../../componentes-globales/ButtonBack/ButtonBack";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const DetallesPedido = () => {

    const { cart, setCart} = useContext(AlbaContext);
    let urlParams = useParams();
     const [pedidoDetails, setPedidoDetails] = useState(null);
     const [cartTouched, setCartTouched] = useState(false);
     const navigate = useNavigate();

     const getPedidoDetails = async (id) => {
        try {
            const response = await axios.get(`${window.location.protocol}//${window.location.hostname}:4040/api/pedidos/${id}`, {
              headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` }
            } 
          );
          setPedidoDetails(response.data)
          }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
    }
    const { pedidoId } = urlParams;

    useEffect(() => {
        getPedidoDetails(pedidoId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoId])

    useEffect(() => {
       if (cartTouched) navigate('/carrito');
     //eslint-disable-next-line react-hooks/exhaustive-deps
    },[cartTouched])

    if (!pedidoDetails) return null;

    const handleVerFactura = () => {
        navigate(`/factura/`, { state: pedidoDetails})    
    }
    const handleComprarDeNuevo = (producto_id, imagen ,nombre_producto, precio, kilos) => (ev) => {
        // setCart
        // producto_id : productoId
        // quantity: 1
        const newItem = {
            producto_id,
            nombre: nombre_producto,
            disponibilidad: "",
            categoria:"",
            quantity: 1,
            pricePerItem: precio,
            type: "",
            precio,
            descripcion: "no description",
            imagen:imagen,
            kilos, 
        }

        setCart(prev => [
            ...prev, 
            newItem
        ]);
        setCartTouched(true);
       // if (cartTouched) navigate('/carrito');
        /* setCart(prev => [
            ...new Map(prev.map(item => [item.producto_id, item])).values()
        ]) */

       // navigate(`/carrito`)
    }

    console.log(cart, "cart");
    const handlePagar = () => {
        navigate(`/metodo-de-pago/${pedidoId}`)
    }
    console.log(pedidoDetails, "pedidoDetails");
    return (
        <div className='contenedor-detalle-pedido'>
             <h2>Detalles del pedido nº {pedidoDetails.id_pedido}</h2> 
            <div className="info-nr-pedido">
                <span>Comprado el {new Date (pedidoDetails.pedido_fecha).toLocaleDateString()}</span>
              
            </div>
            <div className="info-detalle-pedido">
                <div>
                    <div className="address-envio">
                        <h3>Dirección de envío</h3>
                        <div>
                            <p>{`${pedidoDetails.nombre} ${pedidoDetails.apellidos}`}</p>
                            <p>{pedidoDetails.direccion}</p>
                            <p>{`${pedidoDetails.poblacion} ${pedidoDetails.codigopostal}`}</p>
                            {pedidoDetails.provincia && <p>{pedidoDetails.provincia} </p>} 
                            {pedidoDetails.pais && <p>{pedidoDetails.pais===1 && 'España'} </p>} 
                        </div>
                    </div>    
                    <div>
                        <h3>Método de pago</h3>
                        <p>{pedidoDetails.estado}</p>
                        {pedidoDetails.estado ==="nopagado"? <p style= {{fontWeight:"bold", cursor:"pointer"}} onClick ={handlePagar}><span style= {{display:"flex", gap:"10px", alignItems:"center" }} ><FontAwesomeIcon icon={faCreditCard}/>Pagar ahora</span></p>: null}
                    </div>
                </div>
                <div className="info-resumen-compras">
                    <h3>Resumen compras</h3>
                    <section className="productos-del-pedido">
                    {
                        pedidoDetails.productos && pedidoDetails.productos.length>0 && pedidoDetails.productos.map(prod => (
                            <article className="item-pedidos" key={prod?.producto_id}>
                                <picture>
                                    <img src={`../${prod?.imagen}`}  alt='item_img' width="100"/>
                                </picture>
                                <div style={{width:"300px"}}>
                                    <h3>{prod?.nombre_producto}</h3>
                                    <p>Cantidad: {prod?.cantidad} </p>
                                    <p>Precio: {prod?.precio}€ </p> 
                                </div>
                                <div style={{width:"30%"}}>
                                    <ButtonLink label='Comprarlo de nuevo' isEnabled type='button' color='white' backgroundColor='#38b44a'
                                     onClick={handleComprarDeNuevo(prod.producto_id, prod.nombre_producto, prod.precio/prod.cantidad, prod.kilos)} />
                                </div>
                            </article>
                        ))
                    }
                    </section>
                    <p className="transporte-pedido">Transporte: {pedidoDetails.transporte}</p>
                </div>
                <div className="verfactura" onClick= {ev => handleVerFactura()}> <span> Factura<FontAwesomeIcon icon={faDownload} /></span></div>
            </div>
            <div style={{marginTop:"30px"}}>
                 <ButtonBack  onClick={()=>{navigate('/pedidos')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525'/>
            </div> 
        </div>

        
    );
}
export default DetallesPedido;