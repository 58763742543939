import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import { AlbaContext } from "../../context/Context";
import './Carrito.css';
import Compras from "./componentes/Compras/Compras";
import MasProductos from "../../pages/Carrito/componentes/MasProductos/MasProductos";
import ResumenCompras from "../Carrito/componentes/ResumenCompras/ResumenCompras";
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
import { useToken } from "../../auth/useToken";
import ButtonBack from "../../componentes-globales/ButtonBack/ButtonBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import MasProductos from "./componentes/MasProductos/MasProductos";

export const CestaVacia = () => {
    const navigate = useNavigate();
    const handleStartShopping = () =>{
        navigate('/');
    }
        return(
        <div className="cart-empty">
        <h2>Cesta vacía</h2>
        <p>No tienes productos en tu cesta de la compra</p>
        <ButtonLink  label="Empezar a comprar" isEnabled type='button' color='orange' backgroundColor='transparent' onClick={handleStartShopping} />
    </div>
    );
}

export const CestaFull = () => {
    const [token] = useToken()
    const navigate = useNavigate();  
    // const handleClickRealizarPedido = (ev) =>{
    //     // ev.preventDefault();
    //     // navigate('/mi-cuenta')
    //      if (token) navigate('/mi-cuenta')
    //      else navigate("/login" );
    //     console.log(token, 'token')
    // };
    return (
        <div className="cart-full">
            <div  className="shopping-cart-masProductos">
                <div className="shopping-cart">
                    < Compras />
                </div>
                <div className='other-products'>
                    <MasProductos />
                </div>
            </div>
            <div className="realizar-pedido-carrito">
            <div style={{marginTop:"30px"}} className="sigue-comprando">
                <ButtonBack  onClick={()=>{navigate('/')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> Sigue comprando </span>} type='button' color='#fff' backgroundColor='#EA7525' />
            </div>
                <div className="order-summary">
                    <ResumenCompras />
                </div> 
                {/* <ButtonLink onClick={handleClickRealizarPedido} label="Realizar pedido" isEnabled  type='submit' color='white' backgroundColor='#38b44a' /> */}
            </div>
          
        </div>
    );
}

const Carrito = () => {
    const {cart} = useContext(AlbaContext);
    return (
         <div className="cart">{cart.length!==0 ? <CestaFull /> : <CestaVacia/>}
        </div> 

        // <div className="cart">
        //     <CestaFull />
        // </div>
    )
}
export default Carrito;