import React,  {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import './Direcciones.css';
import axios from "axios";
import { AlbaContext } from '../../../../context/Context';
import ButtonLink from "../../../../componentes-globales/ButtonLink/ButtonLink";
import ButtonBack from "../../../../componentes-globales/ButtonBack/ButtonBack";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const Direcciones = () => {
    const { userAddresses, setUserAddresses} = useContext(AlbaContext)
    

    const urlDirecciones = process.env.REACT_APP_BASEURL+'/direcciones';
    
    const getUserAddress = async () => {
        try {
          const response = await axios.get(urlDirecciones, { 
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
          } 
        );

          setUserAddresses(response.data)
         // sessionStorage.setItem('userId', JSON.stringify(response.data.user.customers_id))
         // setLoginSuccess(true);
  
         
        }
        catch (error) {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log("Registration failed");
            }
        }
      }
    const navigate = useNavigate();  
    const handleClickAddAddress = () =>{
      //change to navigate("/otra-direccion") : add this to Routes
        //  navigate("/registrar-direccion" );
        navigate("/otra-direccion");
      };
    useEffect(() => {
        getUserAddress()
    },[]);

      const deleteDireccion = async (id) => {
        try {
          const response = await axios.delete(`${urlDirecciones}/${id}`, { 
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
          } 
        );
          console.log(response);
          getUserAddress()
         // setUserAddresses(response.data)
         // sessionStorage.setItem('userId', JSON.stringify(response.data.user.customers_id))
         // setLoginSuccess(true);
  
        }
        catch (error) {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log("Registration failed");
            }
        }
      }
    
    return (
        <div className="contenedor-direcciones">
            <div className="contenido-direcciones">
                <h2>Mis direcciones</h2>
                <div className="btn_addAddress">
                    <ButtonLink  onClick={handleClickAddAddress} isEnabled label='Añadir otra dirección' type='button' color='#fff' backgroundColor='#EA7525' />
                </div>
            </div>
            {/* <div className="contenido-direcciones"> */}
                <div className="mis-direcciones">
                    {userAddresses.length>0 && userAddresses.map((address) =>(
                        <article key={address.address_book_id} className ="address-card">
                                <p className="address-detail"><strong>
                                {`${address.entry_firstname} ${address.entry_lastname}`}
                                </strong></p>
                                <p className="address-detail">
                                    {address.entry_street_address}
                                </p>
                                <p className="address-detail">
                                    {`${address.entry_city}, ${address.entry_suburb && address.entry_suburb} ${address.entry_postcode}`}
                                </p>
                                <p className="address-detail">
                                    {address.entry_country_id === 1 ? 'España' :'Otro país'}
                                </p>
{/*                                 
                                {address.entry_nif && 
                                    <p className="address-detail">DNI: {address.entry_nif}</p>
                                }  */}
                                 <button className='delete' onClick={()=> deleteDireccion(address.address_book_id)} ><span className="icon-bin"></span></button>
                        </article>))
                    }
                </div>
            
        {/* </div> */}
        <div style={{marginTop:"30px"}}>
            <ButtonBack  onClick={()=>{navigate('/mi-cuenta')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525' />
        </div>
        {/* <ButtonBack /> */}
        </div>
    );
}

export default Direcciones;
