import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AlbaContext } from '../../../../../context/Context';
import ButtonLink from "../../../../../componentes-globales/ButtonLink/ButtonLink";
import { useToken } from "../../../../../auth/useToken";
import fotoatras from '../../../../../assets/images/atras.png';
import './OtraDireccion.css';
import ButtonBack from "../../../../../componentes-globales/ButtonBack/ButtonBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const OtroFormShipping = () => {
    // const region = ["Álava",
    // "Albacete",
    // "Alicante",
    // "Almería",
    // "Asturias",
    // "Ávila",
    // "Badajoz",
    // "Barcelona",
    // "Burgos",
    // "Cáceres",
    // "Cádiz",
    // "Cantabria",
    // "Castellón",
    // "Ciudad Real",
    // "Córdoba",
    // "Cuenca",
    // "Gerona",
    // "Granada",
    // "Guadalajara",
    // "Guipúzcoa",
    // "Huelva",
    // "Huesca",
    // "Islas Baleares",
    // "Jaén",
    // "La Coruña",
    // "La Rioja",
    // "Las Palmas",
    // "León",
    // "Lérida",
    // "Lugo",
    // "Madrid",
    // "Málaga",
    // "Murcia",
    // "Navarra",
    // "Orense",
    // "Palencia",
    // "Pontevedra",
    // "Salamanca",
    // "Santa Cruz de Tenerife",
    // "Segovia",
    // "Sevilla",
    // "Soria",
    // "Tarragona",
    // "Teruel",
    // "Toledo",
    // "Valencia",
    // "Valladolid",
    // "Vizcaya",
    // "Zamora",
    // "Zaragoza"];

    const { contactInfo, setContactInfo } = useContext(AlbaContext);

    return (
        <div className="formShipping-other-address">
            <div className="info-person">
                <label htmlfor="entry_firstname">Nombre/Empresa</label>
                <input type='text' name='entry_firstname' id='name' placeholder="Introduce tu nombre" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, name: ev.target.value }));
                }}  required/>
                <label htmlfor="entry_lastname">Apellidos</label>
                <input type='text' name='entry_lastname' id='surname' placeholder="Introduce tus apellidos" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, surname: ev.target.value }));
                }} required/>
            </div>
            <div className="info-person">
                <label htmlfor="entry_nif">NIF/DNI/NIE (Opcional)</label>
                <input  type='text' name='entry_nif' id='documento' placeholder="NIF / NIE" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, nif: ev.target.value }));
                }}/>
                {/* <input type='text' name='entry_company' id='company' placeholder="Empresa" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, company: ev.target.value }));
                }}/> */}
            </div>
            <div className="city">
                <label htmlfor="entry_city">Población</label>
                <input type='text' name='entry_city' id='city' placeholder="Población" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, city: ev.target.value }));
                }} required/>
                <label htmlfor="phone">Teléfono</label>
                <input type='tel' name='phone' id='phone' placeholder="Teléfono" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, phone: ev.target.value }));;
                }}required/>  
                <label htmlfor="entry_street_address">Dirección</label>
                <input type='text' name='entry_street_address' id='address' placeholder="Dirección" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, address: ev.target.value }));
                }} required/>
                <label htmlfor="entry_postcode">Código Postal</label>
                <input type='text' name='entry_postcode' id='postal_code' placeholder="Código Postal" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, postalCode: ev.target.value }));
                }} pattern="[0-9]{5,}" required/>
            </div>
            <label htmlfor="entry_country_id">País</label>
            <div className="country-region">
                <select name='entry_country_id' id='select-country' onChange ={(ev) =>{
                    setContactInfo(prev=>({...prev, country: ev.target.value }));
                }} required>
                        <option value={0}>Elige</option>
                        <option value={1}>España</option>

                    </select>
                    {/* <select name='entry_suburb' id='select-region' onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, provincia: ev.target.value }));
                }} required>
                        <option value=''>Provincia</option>
                        {
                            region.map(item =>( 
                                <option value={item} key={item}>{item}</option>
                            ))
                        }
                    </select> */}
                
            </div>
            {/* <div className="city">
                <input type='text' name='entry_city' id='city' placeholder="Ciudad" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, city: ev.target.value }));
                }} required/>
                <input type='text' name='entry_postcode' id='postal_code' placeholder="Código Postal" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, postalCode: ev.target.value }));
                }} pattern="[0-9]{5,}" required/>
            </div>
             <input type='tel' name='phone' id='phone' placeholder="Teléfono" onChange ={(ev) =>{
                        setContactInfo(prev=>({...prev, phone: ev.target.value }));;
                }}required/>     */}
        </div> 
    );
}

 const OtraDireccion = ()=> {
    const navigate = useNavigate();  
    const { contactInfo, setContactInfo } = useContext(AlbaContext);
    const [token] = useToken()

    const handleSubmitInfoBuyer = async (ev) =>{
        ev.preventDefault();

        const addressBookBody = {
            entry_firstname: contactInfo.name, 
            entry_lastname: contactInfo.surname, 
            entry_street_address: contactInfo.address,
            entry_suburb: contactInfo.provincia, 
            entry_postcode: contactInfo.postalCode, 
            entry_city: contactInfo.city, 
            entry_country_id: 1, 
            entry_nif: contactInfo.nif
         }
         try {
            const response = await axios.post(process.env.REACT_APP_BASEURL+'/direcciones', addressBookBody, { 
                headers: { 'Authorization': `Bearer ${token}` },
            }); 

            console.log(response.data, 'response direcciones')

          }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }

        navigate("/direcciones" );
       // setContactInfo('');
    };
    
    return(
        <div className="container-other-address">
            <div className="payment-registration">
                <form action="#" method="post" className="formulario-payment"  id="form-other-address" onSubmit={handleSubmitInfoBuyer}> 
                    <div className="shipping">
                        <h2>Dirección de entrega</h2> 
                        <OtroFormShipping />
                    </div>
                    <ButtonLink  label="Nueva dirección" isEnabled type='submit' color='white' backgroundColor='#38b44a' />
                </form> 
                {/* <div className="volver-mi-direcciones" onClick={() => {navigate('/direcciones')}}>
                    <span>Atras</span> 
                    <span> <img src={fotoatras} alt='foto-atras' className="foto_atras"/></span>
            </div> */}
                
            </div>
            <div style={{marginTop:"30px"}}>
                <ButtonBack  onClick={()=>{navigate('/direcciones')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525' />
            </div>
        </div>
    )
}
export default OtraDireccion;