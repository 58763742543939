
import { useContext, useEffect, useState } from "react";
import { AlbaContext } from '../../../../context/Context'
import { useLocation } from "react-router-dom";

const CartItem = ({cartItem}) => {
    const { cart,setCart, total, setTotal } = useContext(AlbaContext)
    const [quantity, setQuantity] = useState(cartItem.quantity);
    const [itemInCart, setItemInCart] = useState(cartItem)
console.log(cartItem, "cartItem");
    const itemTotal = parseInt(cartItem.precio)*quantity;
    
    // const location = useLocation();
    // const product = location.state; 
    
    const handleDecrement = (ev) => {
      setQuantity(prevQty => (
        prevQty > 1 ? (prevQty - 1) : 0
       ))
    }

    const handleIncrement = () => {
        setQuantity(prevQty => prevQty + 1)
      }

    const handleRemoveItem = (cartItem)=>{
        setCart(prevCart => prevCart.filter(item => item.producto_id !== cartItem.producto_id));
      }
     
    useEffect(() => {
        setItemInCart(prev => ({
            ...prev,
            quantity,
            pricePerItem: itemTotal
        }))
        
    },[quantity])

    useEffect(() => {
        setCart(prevCart=> (
            prevCart.map(item => item.producto_id !== cartItem.producto_id ? item : itemInCart)
        ))
    },[itemInCart])

    useEffect(() => {
        setTotal(prev => [...cart].map(cItem => cItem.pricePerItem).reduce((cur, acc) => cur+acc))
    },[cartItem])
        
        return (
            <article  className="product-display"key={itemInCart.producto_id}>
                <picture>
                    <img src={`../${itemInCart.imagen}`} alt='item_img'/>
                </picture>
                <span className="title-product">{itemInCart.nombre}</span>
                <span className='price'><span className="price-unit">{itemInCart.precio}</span><span>€</span></span>
                <div className="quantity">
                    <button className="btn-minus" onClick={handleDecrement}>-</button>
                    {/* <input type='text' name='quantities' id ='quantities' value={cartItem.quantity}/> */}
                    <span>{quantity}</span>
                    <button className="btn-plus" onClick={handleIncrement}>+</button>
                </div>
                <span className='price'>
                    <span className="price-total">{itemInCart.pricePerItem}
                    </span>
                    <span>€</span>
                </span> 
                <button className='delete' onClick={()=>{handleRemoveItem(cartItem)}} ><span className="icon-bin"></span></button> 
            </article>
        )
    }

export default CartItem;
