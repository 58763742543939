import { useState, useEffect } from "react";

export const useFetchProducts = () => {

  const [products, setProducts] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [errorProducts, setErrorProducts] = useState(null);
  const productsUrl = process.env.REACT_APP_BASEURL + '/productos/destacados';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProducts(true);
      try {
        const response = await fetch(productsUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingProducts(false);
        setErrorProducts(null);
        setProducts(json);
      } catch (error) {
        setErrorProducts(`${error} Could not Fetch Data `);
        setIsLoadingProducts(false);
      }
    };
    fetchData();
  }, [productsUrl]);

  return { products, isLoadingProducts, errorProducts };
};
