import { useState, useEffect } from "react";

export const useFetchCategorias = () => {

  const [data, setData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const categoriasUrl = `${process.env.REACT_APP_BASEURL}/categorias`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const response = await fetch(categoriasUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingData(false);
        setErrorData(null);
        setData(json);
      } catch (error) {
        setErrorData(`${error} Could not Fetch Data `);
        setIsLoadingData(false);
      }
    };
    fetchData();
  }, [categoriasUrl]);

  return { data, isLoadingData, errorData };
};
