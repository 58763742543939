import React from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useLocation } from "react-router-dom";
import logo from "../../../../assets/images/LOGO11111.jpg"
// import './Factura.css';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'colum',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: '10px',
    padding: '10px',
    flexGrow: 1
  },
  contenidoFactura:{
    display:"flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    margin:"30px"
  },
  detalles:{
    width:"50%",
},
imag:{
    maxWidth:"150px",
    display:"block"
},
title:{
    color:"#1004C0",
    textTransform: "uppercase",
    padding: "4px",
    paddingBottom: "20px",
    fontWeight:"bold",
},
direccion:{
    margin:0,
    padding: "4px", 
    fontSize:"10px",
},
comprador:{
    border:"1px solid #1004C0 ",
    width:"70%",
    backgroundColor: "#1004C0",
    color:"white",
    padding:"5px" ,
    marginTop: "30px"
},
factura:{
    width:"50%"
},
titleFactura:{
    width:"100%",
    color:"#1004C0",
    padding:"5px" ,
    marginBottom:"20px",
    fontSize:"50px",
    textAlign:"right"
},
infoFactura: {
    display:"flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin:"20px",
},
titleFecha:{
    border:"1px solid #1004C0",
    width:"100%",
    backgroundColor: "#1004C0",
    color:"white",
    padding:"5px" ,
    marginTop: "30px",
    paddingRight:"100px",
    textAlign: "left",
},
infoTitleFecha:{
     textAlign: "left",
     paddingTop:"20px" ,
     paddingRight:"100px",
     margin:"0 auto",
     width:"100%",
},
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    margin:"30px"
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    margin: "auto", 
    marginTop: '5px', 
    fontSize: '10px',
    paddingBottom: '4px'
  },
});

const Factura = () => {

   const location = useLocation();
   const facturaDetails = location.state;
   const IvaFactura = Number(facturaDetails.total)*0.07;
   const facturaTotalConIva= Number(facturaDetails.total)+IvaFactura;

   if (!facturaDetails) return null;

   return(
    <PDFViewer style= {{width:"100%", height:"100vh"}}> 
         <Document> 
            <Page size="A4"style={styles.page} >
                {/* <View style={styles.section}> */}
                         <View style={styles.contenidoFactura}>
                            <View style={styles.detalles}>
                                <View>
                                        <View>
                                            <Image 
                                            //src ={{ uri: "https://trello.com/1/cards/5d1c6954d862c17ac9d2a576/attachments/6332dc5dd0ce4b0262665e69/previews/6332dc5ed0ce4b02626660bb/download/LOGO11111.jpg", method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                                            src={logo}
                                            alt='item_img' cache={false} style={styles.imag}/>
                                            <Text style={styles.title}>Hort Alba Garcés</Text>
                                        </View>
                                        
                                        <View>
                                            <Text style={styles.direccion}>Cami dels horts s/n </Text>
                                            <Text style={styles.direccion}>Picanya, 46210, España</Text>
                                            <Text style={styles.direccion}>Telefono: 961591344</Text>
                                            <Text style={styles.direccion}>CIF: 24307746N</Text>
                                        </View>
                                </View>
                                <View>
                                        <Text style={styles.comprador}> Facturar a</Text>
                                        <View>
                                            <Text style={styles.direccion}>{`${facturaDetails.nombre} ${facturaDetails.apellidos}`}</Text>
                                            <Text style={styles.direccion}>{facturaDetails.direccion}</Text>
                                            <Text style={styles.direccion}>{`${facturaDetails.poblacion} ${facturaDetails.codigopostal}`}</Text>
                                            <Text style={styles.direccion}>{facturaDetails.pais=='1' && 'España'}</Text>
                                            <Text style={styles.direccion}>{facturaDetails.email}</Text>
                                             <Text style={styles.direccion}>{facturaDetails.telefono ? facturaDetails.telefono  : null}</Text>
                                        </View>
                                </View>
                            </View>
                            <View style={styles.factura}>
                                    <Text style={styles.titleFactura}>Factura</Text>
                                    <View style={styles.infoFactura}>
                                        <View> 
                                            <Text style={styles.titleFecha}>Factura </Text>
                                            <Text style={styles.infoTitleFecha}>{new Date().getFullYear()}{facturaDetails.id_pedido}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.titleFecha}>Fecha </Text>
                                            <Text style={styles.infoTitleFecha}>{new Date (facturaDetails.pedido_fecha).toLocaleDateString()}</Text>
                                        </View>
                                    </View>
                             </View>
                        </View>
                {/* </View> */}
                <View style={styles.table}>
                     <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Descripcion</Text> 
                        </View>
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Cant</Text> 
                        </View>
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Precio unitario</Text> 
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Precio total </Text> 
                        </View> 
                    </View>
                    <View>
                        {facturaDetails.productos.map(prod => (
                        <View style={styles.tableRow} key={prod.pedido_producto_id}>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell,{textAlign:"left", paddingLeft:"5px",marginLeft:0}]}>{prod.nombre_producto}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{prod.cantidad}  </Text> 
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{prod.precio/prod.cantidad}€</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{prod.precio}€</Text>
                            </View>
                        </View> 
                        ))}
                    </View>
                     <View style={styles.tableRow}>
                        <View style={[styles.tableCol, {width:"50%", borderRightWidth:1, paddingTop:"15px" }]}>
                            <Text style={[styles.tableCell, {fontSize:"12px"}]}></Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>SubTotal</Text>
                            <Text style={styles.tableCell}>IVA (7%) </Text>
                            <Text style={[styles.tableCell, {fontWeight:"bold", fontSize:"15px"}]}> TOTAL</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{facturaDetails.total} €</Text>
                            <Text style={styles.tableCell}>{IvaFactura} €</Text>
                            <Text style={[styles.tableCell, {fontWeight:"bold", fontSize:"15px"}]}>{facturaTotalConIva} €</Text>
                        </View> 
                    </View>
                 </View>
             </Page>
        </Document>
    </PDFViewer>
);
}
export default Factura;

