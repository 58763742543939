// import React from "react";
// import { useNavigate } from "react-router-dom";
// import fotoatras from '../../assets/images/atras.png';
// import './ButtonBack.css';

// const ButtonBack = () =>{
//     const navigate = useNavigate();
//  return (
//     <div className="volver-mi-cuenta" onClick={() => {navigate('/mi-cuenta')}}>
//         <span>Atras</span> 
//         <span> <img src={fotoatras} alt='foto-atras' className="foto_atras"/></span>
//     </div>
//     );
// }
// export default ButtonBack;
import React from "react";
import './ButtonBack.css'
const ButtonBack = ({type, label, onClick, color, backgroundColor})=>{
    return(
        <button type={type} onClick={onClick} style={{border:`3px solid ${color}`, color:color, backgroundColor:backgroundColor}}  >
            {label}
        </button>
    )
}
export default ButtonBack;