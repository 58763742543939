import React from "react";


const DecodeJWT = (token) =>{
    const parts = token.split(".");
    if (parts.lenght === 3){
        console.log ('Invalid token format');
    }
    const header = JSON.parse(atob(parts[0]));
    const payload = JSON.parse(atob(parts[1]));

    return{header,payload};
}
export default DecodeJWT;