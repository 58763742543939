import { useState, useEffect } from "react";

export const useFetchInternationalization = (language) => {

  const [dataInternationalization, setDataInternationalization] = useState(null);
  const [isLoadingDataInternationalization, setIsLoadingDataInternationalization] = useState(false);
  const [errorDataInternationalization, setErrorDataInternationalization] = useState(null);
  const internationalizationUrl = `${process.env.REACT_APP_BASEURL}/internationalization/${language}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingDataInternationalization(true);
      try {
        const response = await fetch(internationalizationUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingDataInternationalization(false);
        setErrorDataInternationalization(null);
        setDataInternationalization(json);
      } catch (error) {
        setErrorDataInternationalization(`${error} Could not Fetch Data `);
        setIsLoadingDataInternationalization(false);
      }
    };
    fetchData();
  }, [internationalizationUrl]);

  return { dataInternationalization, isLoadingDataInternationalization, errorDataInternationalization };
};
