import React, { useContext, useEffect, useState } from "react";
import './CajaMedida.css';
import Breadcrumbs from "../../componentes-globales/Breadcrumbs/Breabcrumbs";
import ButtonLink from "../../componentes-globales/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import cajaMedida from '../../assets/images/caja-a-medida2.jpg';
import { useFetchProductosBandejas } from "../../componentes-globales/Hooks/useFetchProductosBandejas";
import { AlbaContext } from "../../context/Context";

const CajaMedida = () => {

    const { productos, isLoadingProductos, errorProductos } = useFetchProductosBandejas();
    const [ box, setBox ] = useState([]);
    const [ filteredBox, setFilteredBox ] = useState([]);
    const [ item, setItem ] = useState(null);
    const [ totalKilos, setTotalKilos ] = useState(0);
    const { cart, setCart } = useContext(AlbaContext);

    useEffect(() => {
        const prepareProducts = productos && productos?.productosBandejas?.map((prod, key) => ({
            ...prod,
            id: key + 1,
            cantidad: 0,
            kilos: 0,
            precioTotal: prod.precio
        }));
        setBox(prepareProducts)
        setItem(productos?.cajasDisponibles?.[0])
    }, [productos]);

    useEffect(() => {
        setFilteredBox(box?.filter(item => item.cantidad > 0))
    }, [box])

    const maxKilos = 18;
    console.log(filteredBox,'filtered');
    

    const handleIncrement = (item) => {
        const currentBox = box.find(caja => caja.id === item.id);
        setBox(() => {
            return [
            ...box.slice(0, item.id-1),
            {
                id_bandeja: currentBox.id_bandeja,
                producto: currentBox.producto,
                precio: currentBox.precio,
                id: currentBox.id,
                cantidad: currentBox.cantidad + 1,
                kilos: currentBox.kilos + 6,
                precioTotal: currentBox.precio * (currentBox.cantidad + 1)
            },
            ...box.slice(item.id)
            ];
    });
        setTotalKilos(prev => prev + 6 );
    };

    console.log(box, 'box');

    const handleDecrement = (item) => {
        const currentBox = box.find(caja => caja.id === item.id);
        if (item.cantidad === 0) return;
        setBox(() => {
          return [
          ...box.slice(0, item.id-1),
          {
            id_bandeja: currentBox.id_bandeja,
            producto: currentBox.producto,
            precio: currentBox.precio,
            id: currentBox.id,
            cantidad: currentBox.cantidad - 1,
            kilos: currentBox.kilos - 6,
            precioTotal: currentBox.precio * (currentBox.cantidad - 1)
          },
          ...box.slice(item.id)
        ];
        });
        setTotalKilos(prev => prev - 6 );
    }

    const total = (filteredBox && filteredBox?.length) ? filteredBox?.reduce((acc, current) => acc + (current.cantidad * current.precio), 0) : 0;
    const concludingMsj = 
        totalKilos === 18 ? 'Caja completa' : 
        totalKilos === 12 ? 'Te falta 1 producto' :
        totalKilos === 6 ? 'Te faltan 2 productos' :
        'Por favor añade 3 productos';

    if (errorProductos) return null;
    if (isLoadingProductos) return <div>Cargando...</div>

    const mapItemToCart = (filteredBox, item, totalPrice) => {
        const description = filteredBox.map((caja) => `${caja.producto} - ${caja.kilos}kg`).join(', ');
        console.log(description);
        let bandejaId1, bandejaId2, bandejaId3 = 0;
        function defineBandejas(bandejas) {
            if (bandejas.length === 3) {
               bandejaId1 = bandejas[0].id_bandeja;
               bandejaId2 = bandejas[1].id_bandeja;
               bandejaId3 = bandejas[2].id_bandeja;
            } else if (bandejas.length === 2) {
                const bandeja12 = bandejas.find(bandeja => bandeja.kilos===12).id_bandeja;
                const bandeja6 = bandejas.find(bandeja => bandeja.kilos===6).id_bandeja;
                bandejaId1 = bandeja12;
                bandejaId2 = bandeja12;
                bandejaId3 = bandeja6;
               
            } else if (bandejas.length === 1) {
               bandejaId1 = bandejaId2 = bandejaId3 = bandejas[0].id_bandeja;

            }
        }
        defineBandejas(filteredBox);
        const newItem = {
            bandejaId1,
            bandejaId2,
            bandejaId3,
            categoria:item.categoria,
            descripcion: description,
            disponibilidad:item.disponibilidad,
            imagen: item.imagen,
            kilos: item.kilos,
            nombre:item.nombre,
            precio: totalPrice,
            pricePerItem: totalPrice,
            producto_id:item.producto_id,
            quantity: 1,
            type: item.categoria,
        }
        return newItem;
    }

    const handleAddToCart = () => {
        setCart(prev => [
            ...prev, 
            mapItemToCart(filteredBox, item, total)
            ])
            setCart(prev => [
            ...new Map(prev.map(item => [item.producto_id, item])).values()
            ])
    }

    return (
        <div className="container-caja-medida">
             <Breadcrumbs />
             <div className="first-section contenido-section"> 
                <h2> Haz la combinación que deseas para tu caja</h2>
             </div>
             <div className="second-section contenido-section"> 
                <section className="section-comp section">
                    <p className='title-section-comp'><span>1</span>Elije tus variedades deseadas</p>
                    <div className="variedades-bandeja">
                        {
                            box && box?.map((item) => (
                                <article className="set-caja-articulo" key={item?.id_bandeja}>
                                    <button className="btn-minus" disabled={totalKilos === 0} onClick={() => handleDecrement(item)}>-</button>
                                    {/* <input type='text' name='quantities' id ='quantities' value={cartItem.quantity}/> */}
                                    <span>{`${item?.producto} (${item?.precio}€ / 6kg)`}</span>
                                    <button className="btn-plus" disabled={totalKilos >= maxKilos} onClick={/*console.log(item)*/() => handleIncrement(item) }>+</button>
                                </article>
                            ))
                        }
                    </div>
                </section>
                <section className="section-comp image-article">
                    <picture className="img-product">
                        <img src={cajaMedida} alt='foto-caja' className="foto_caja"/>
                    </picture>
                </section>

                <section className="section-comp">
                    <p className='title-section-comp'><span>2</span>Contenido de tu caja de 18 kg</p>
                    <div className="">
                        <div className="cantidad-bandeja">
                            {
                                (totalKilos > 0 && filteredBox?.length) ? filteredBox?.map((item) => (
                                    <article key={item?.id_bandeja} className="caja-combinacion">
                                        <label className="cantidad-label" htmlFor="cantidad1-bandeja">{item?.producto}</label>
                                        <span>{`${item.kilos} kilos`} </span>
                                        <span>{item.precioTotal}€</span>
                                    </article>
                                )) : (
                                    <div>{concludingMsj}</div>
                                )
                            }
                        </div>
                        {totalKilos > 0 && <p className={`${totalKilos===18 && 'caja-completa'} total-peso`}>{concludingMsj}</p>} 
                        <p className="precio-total">Total: {total}€</p>
                    </div>
                </section>

             </div>
             <div className="third-section contenido-section">
                {/* <picture className="img-product">
                    <img src={cajaMedida} alt='foto-caja' className="foto_caja"/>
                </picture> */}
                <div>
                    {/* <p>Total: {precioTotal}€</p> */}
                    <p className="add-to-cart">
                        <Link to="/carrito"> 
                            <ButtonLink onClick={handleAddToCart} icon={faCartShopping}
                            label={'Añadir al carro'} isEnabled={totalKilos === maxKilos}color='white' backgroundColor='#38b44a' />
                        </Link>    
                    </p>
                 </div>
                </div>
        </div>
    )
}
export default CajaMedida;
