import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import './MiCuenta.css';
import direcciones from "../../../assets/images/direcciones.png";
import pedidos from "../../../assets/images/entrega-de-pedidos.png";
import pagos from "../../../assets/images/tarjetas-de-credito.png";
import usuario from "../../../assets/images/usuario.png";
import { AlbaContext } from "../../../context/Context";
import ButtonLink from "../../../componentes-globales/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faArrowRightFromBracket, faForward} from "@fortawesome/free-solid-svg-icons";

const ContinueShopping = () => {
    const {cart} = useContext(AlbaContext);
    const navigate = useNavigate()

    return (
        <section className='shopping-cart button-micuenta'>
            {
                cart.length!==0 && (
                    <div>
                        <span> <FontAwesomeIcon icon={faAnglesRight} /></span>
                        <ButtonLink label="Sigue con tu compra" isEnabled onClick={() => navigate('/confirmar-pedido')}/>
            {/* <ButtonLink label="Sigue con tu compra" isEnabled onClick={() => navigate('/confirmar-pedido')}/> */}
                    </div>
                )
            } 
        </section>
    )
}

const MiCuenta = () => {
    const { customer, contactInfo, setCustomer, cart, setCart } = useContext(AlbaContext);
    const customerFormattedName = customer?.customers_firstname && customer.customers_firstname.toLowerCase();
    const navigate = useNavigate();

    const handleClickDirecciones = ()=>{
         navigate('/direcciones');
    }
    const handleClickMisPedidos = ()=>{
        navigate('/pedidos');
    }
    // const handleClickMisPagos = ()=>{
    //      navigate('/pagos');
    // }
    const handleClickMisDatos = ()=>{
         navigate('/datos-personales');
    }
   
    return(
        <section className="contenedor-mi-cuenta">
            <h2 className="welcome">bienvenid@ {customerFormattedName}</h2> 
            <div className="header-mi-cuenta button-micuenta">   
                    <ContinueShopping />   
                    {/* <p onClick={() => {
                        sessionStorage.clear();
                        setCustomer(null)
                        setCart('')
                        navigate('/')}} ><FontAwesomeIcon icon={faArrowRightFromBracket}/></p> */}
                    <div >
                        <ButtonLink label={'Logout'} icon={faArrowRightFromBracket} isEnabled color='white' backgroundColor='#EA7525' onClick={() =>{sessionStorage.clear();
                        setCustomer(null)
                        setCart('') 
                        navigate('/')}}/>
                    </div>
                
            </div>
            <div className="container-mi-cuenta">
                <div className="componentes-mi-cuenta">
                    <h2 onClick= {handleClickDirecciones}>Direcciones</h2> 
                    <div>
                        <div className="img-mi-cuenta">
                            <img src={direcciones} className="img-cuenta" alt="img2" />
                        </div>
                        <div className="items-mi-cuenta">
                            {/* <h2 onClick= {handleClickDirecciones}>Direcciones</h2>  */}
                            <p>Editar direcciones y preferencias de envío para pedidos</p>
                        </div>
                    </div>
                </div>
                <div className="componentes-mi-cuenta">
                    <h2 onClick= {handleClickMisPedidos}>Mis pedidos</h2>
                    <div> 
                        <div className="img-mi-cuenta">
                            <img src={pedidos} className="img-cuenta" alt="img3" />
                        </div>
                        <div className="items-mi-cuenta">
                            
                            <p>Realizar el seguimento, devolver un producto o repetir compras anteriores</p>
                        </div>
                    </div>
                </div>
                 {/*<div className="componentes-mi-cuenta">
                    <div className="img-mi-cuenta">
                        <img src={pagos} className="img-cuenta" alt="img5" />
                    </div>
                    <div className="items-mi-cuenta">
                        <h2 onClick= {handleClickMisPagos}>Mis pagos</h2> 
                        <p>Gestionar métodos de pago y configuración, ver todas las transacciones</p>
                    </div> 
                </div>*/}

                <div className="componentes-mi-cuenta">
                    <h2 onClick= {handleClickMisDatos}>Perfil</h2> 
                    <div>
                        <div className="img-mi-cuenta">
                            <img src={usuario} className="img-cuenta" alt="img4" width="63" />
                        </div>
                        <div className="items-mi-cuenta">
                            <p>Editar datos personales</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MiCuenta;