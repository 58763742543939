import React, { useContext } from "react";
import './ProductsList.css';
import ButtonLink from "../ButtonLink/ButtonLink";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { AlbaContext } from '../../context/Context';

function adaptProductUrl(productName) {
    return productName.replaceAll(' ','-');
}

const ProductCard = ({ item }) => {

    const { setCart } = useContext(AlbaContext);
    const navigate = useNavigate();
    const quantity = 1;
    const navigateTo = async(product) => {
        navigate(`/${adaptProductUrl(product.nombre)}`, {
            state: product
        })
    };
    const newItem = {
        producto_id:item.producto_id,
        nombre:item.nombre,
        disponibilidad:item.disponibilidad,
        categoria:item.categoria,
        quantity: quantity,
        pricePerItem: item.precio*quantity,
        type: item?.type || null,
        precio: item.precio,
        descripcion: item.descripcion,
        imagen: item.imagen,
        kilos: item.kilos,
    };

    const handleAddToCart = () => {
        setCart(prev => [
        ...prev, 
        newItem
        ])
        setCart(prev => [
        ...new Map(prev.map(item => [item.producto_id, item])).values()
        ])
    };

    return (
        <article key={item.producto_id} className="product-list">
            <div className="producto-details" >
                <div onClick={() => navigateTo(item)}>
                    <picture className="img-product">
                    <img src={`../${item.imagen}`} alt={item.nombre} width="270" />
                    </picture>
                    <p className="product-name">{item.nombre}</p>
                </div>
            </div>
            
            <p className="product-price">Precio caja <span className="precio-caja">{`${item.precio} €  `} </span><span>{`- ${(item.precio / Number(item.kilos)).toFixed(2)} €/kg `}</span></p>
            <p className="btn-add-to-cart">
                {
                    item.disponibilidad!=='No Disponible' ? (
                        <Link to="/carrito"> 
                            <ButtonLink onClick={handleAddToCart} icon={faCartShopping} label={'Añadir al carro'} 
                                isEnabled color='white' backgroundColor={'#38b44a'} />
                        </Link>
                        ) : (
                        <ButtonLink onClick={handleAddToCart} icon={faCartShopping} label={'En espera'} 
                            isEnabled={false} color='white' backgroundColor={'#EA7525'} />
                        )
                }
            </p>
        </article>
    )
}

const ProductsList = ({ products }) => {

    return (
        <section className="container-list">
            {products && products?.map((product) => <ProductCard item={product} />)}
        </section> 
    )
}

export default ProductsList;
