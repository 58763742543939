import React, { useContext, useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import './Header.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquareFacebook, faSquareInstagram, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faCartShopping, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import useClickOutside from "../../utils/hooks/useClickOutside";
import { AlbaContext } from "../../context/Context";


const Header = ({flags, categorias, menuPrincipal, menuSecondary}) => {
    const navigate = useNavigate();
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
    const [isOpenFlags, setIsOpenFlags] = useState(false);
    const handleOpenSubMenu = () =>{
        setIsOpenSubMenu(prev => !prev);
    }
    const handleOpenFlagMenu = () => {
        setIsOpenFlags(prev => !prev);
    }
    const refSubMenu= useRef(null);
    const refFlags = useRef(null);

    useClickOutside(refSubMenu,() => setIsOpenSubMenu(false));
    useClickOutside(refFlags,() => setIsOpenFlags(false));
    
    const { cart,setCart, customer, setCustomer, language } = useContext(AlbaContext);
    const customerInitial = customer?.customers_firstname.charAt(0).toUpperCase();
 
    return (
    <div className="header" >
        <div className="container">
            <div className="row-header logo">
                <div className="col-logo" >
                            <Link to="../"> 
                                <picture className="logo-header">
                                     <img src={require(`../../assets/images/LOGOcut.png`)} alt='logo_img' width="300"/>
                                </picture>
                            </Link>
                </div>            
                <div className="col-intranet-redes">
                     <div className="col-redes">
                        <Link rel="nofollow" target="_blank" to="https://www.facebook.com/pages/Naranjas-Hort-Alba-Garc%C3%A9s/102986254619?fref=ts" className=" btn-social-icon btn-facebook ">
                            <FontAwesomeIcon icon={faSquareFacebook} style={{color: "#74C0FC",}}/>
                        </Link>
                        <Link rel="nofollow" target="_blank" to="https://twitter.com/hortalbagarces" className="btn-social-icon btn-twitter">
                            <FontAwesomeIcon icon={faXTwitter} style={{color: "#74C0FC",}} />
                        </Link>
                        <Link rel="nofollow" target="_blank" to="https://instagram.com/hortalbagarces" className="btn-social-icon btn-instagram">
                            <FontAwesomeIcon icon={faSquareInstagram} style={{color: "#74C0FC",}} />
                        </Link>
                        <Link  to="../contacto.php" className="btn-phone">
                            {/* <FontAwesomeIcon icon={faPhone} style={{color: "#dd4814" }}/> */}
                            <FontAwesomeIcon icon={faPhone} style={{color: "#74C0FC" }}/>
                        </Link>  
                     </div>
                    <div className="col-intranet">
                        <div className="btn-group pull-right header-button">
                            {/* <Link to="/intranet"><button className="btn-primary btn btn-sm"><FontAwesomeIcon icon={faUser} style={{color: "#ffffff",}}/> Mi cuenta{ */}   
                             <div className="avatar">
                             { customer ? (
                                        <>
                                            <div className="user-offline">
                                                <span className="customer-name">{customerInitial}</span>
                                                
                                            </div>
                                            <ul className="customer_nav">
                                                <li onClick={() => navigate('/mi-cuenta')}>Mi cuenta</li>
                                                {/* <li><Link to='/mi-cuenta'>Mi cuenta</Link></li> */}
                                                <li onClick={() => {
                                                    sessionStorage.clear();
                                                    setCustomer(null)
                                                    setCart('')
                                                    navigate('/')}}>
                                                        Desconectar
                                                </li>
                                            </ul>
                                        </>
                              ) : (
                                     <div className="icon-user-log-in">
                                            <Link to="/intranet"><button className="btn-primary btn btn-sm"><FontAwesomeIcon icon={faUser} style={{color: "#ffffff",}}/><span>Mi cuenta</span></button></Link>
                                      </div> )
                                }
                             </div>
                            
                            <Link to="../carrito"><button className="btn-success btn btn-sm"><FontAwesomeIcon icon={faCartShopping} style={{color: "#ffffff",}}/> {cart.length}</button></Link>
                        </div>

                        <div className="dropdown pull-right flags open">
                            <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-expanded="true" onClick={handleOpenFlagMenu}>
                                <img src={require(`../../assets/images/flags/es.png`)} alt='es_flag'/>
                                <span className="caret"></span>
                            </button>
                            <ul ref={refFlags} className={`dropdown-menu ${isOpenFlags ? 'dropdown-open' : ''}`}  role="menu" aria-labelledby="dropdownMenu1">
                                        {
                                         flags && flags?.map((flag) => (
                                            <li role="presentation">
                                                <Link rel="nofollow" to={flag.urlTo}><img src={require(`../../assets/images/flags/${flag.urlImage}`)} alt='flag'/>&nbsp;&nbsp;{flag.name} </Link>
                                            </li>
                                        ))
                                    }
                                <li role="presentation"><Link role="menuitem" tabIndex="-1" to="https://www.valenciasoranges.com"><img src={require(`../../assets/images/flags/en.png`)} alt='en_flag'/>&nbsp;&nbsp;English</Link></li> 
                            </ul>
                        </div>
                                {/* <Link to="../"> 
                                    <picture className="logo-text">
                                        <img src={require(`../../assets/images/logo-white.png`)} alt='item_img'/>
                                    </picture>
                                </Link> */}
                    </div>                  
                </div>
            </div>
            <div className="row-header menu">
                <nav className="menu-horizontal">
                <input type="checkbox" id="menu-responsive" className="input-responsive" />
                    <label htmlFor="menu-responsive" className="label-responsive" ><span className="menu-hamburger"></span><span className="menu-hamburger"></span><span className="menu-hamburger"></span></label>
                    <ul className="nav-list navbar-nav side-collapse">
                        {
                            menuPrincipal?.map(menuItem => (
                                <li className="product-header"><Link to={language==='es' ? menuItem.url_es : menuItem.url}>{menuItem[language]}</Link></li>
                            ))
                        }
                        <li className="product-header dropdown hidden-sm" onClick={handleOpenSubMenu}>
                            Otros productos
                            <span className="caret"></span>
                            <ul ref={refSubMenu} className= {`dropdown-menu ${isOpenSubMenu ? 'dropdown-open' : ''}`}  role="menu" >
                                {
                                    menuSecondary?.map(menuItem => (
                                        <li><Link to={language==='es' ? menuItem.url_es : menuItem.url}>{menuItem[language]}</Link></li>
                                    ))
                                }
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div> 
        </div>
    </div>
    );
}
export default Header;
