import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ButtonLink.css'

const ButtonLink = ({type, icon, label, onClick, color, backgroundColor, isEnabled, form})=>{
    return(
        <button className="button-link" type={type} form={form} onClick={onClick} disabled={!isEnabled} style={{border:`3px solid ${color}`, color:color, backgroundColor:backgroundColor }}  >
            <span className={`${icon ? 'cart-icon' : ''}`} ><FontAwesomeIcon icon={icon}/>{label}</span>
        </button>
    )
}
export default ButtonLink;
