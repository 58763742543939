import React, { useContext, useEffect, useState } from "react";
import axios from 'axios'
import visa from '../../../../../assets/images/visa.png';
import mastercard from '../../../../../assets/images/mastercard.png';
import paypal from '../../../../../assets/images/paypal.png';
import { useNavigate, useParams} from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";
import './MetodoDelPago.css';
import { AlbaContext } from "../../../../../context/Context";
import { useToken } from "../../../../../auth/useToken";
import ButtonLink from "../../../../../componentes-globales/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import ButtonBack from "../../../../../componentes-globales/ButtonBack/ButtonBack";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const MetodoDelPago = () => {
    let urlParams = useParams();
    const { pedidoId } = urlParams;
    const { userAddresses, idPedido }=useContext(AlbaContext);
    const [orderId, setOrderId]=useState(null);
    const [token] = useToken();
    const [isCardSelected, setIsCardSelected] = useState(false);
    const [isPaypalSelected, setIsPaypalSelected] = useState(false);
    console.log(pedidoId, "pedidoId");
    console.log(idPedido, ' id pedido from Context')
    const navigate = useNavigate();

    const onchangeCreditcard = (e) => {
      if (e.target.checked) {
        setIsCardSelected(true);
        setIsPaypalSelected(false);
      } else {
        e.preventDefault()
        e.stopPropagation();
      }
    }
    const onchangePaypal = (e) => {
      if (e.target.checked) {
        setIsPaypalSelected(true);
        setIsCardSelected(false);
      } else {
        e.preventDefault()
        e.stopPropagation();
      }
    }

          /* --------------PAGO CON TARJETA-----------*/

        const PaymentForm = () => {
        
          const handleSubmitPagar = async (e) => {
            e.preventDefault();
        
            // Redirect the user to the Redsys payment page 
            const redirectUrl = 'https://sis-t.redsys.es:25443/sis/realizarPago'; // Replace with https://sis.redsys.es/sis/realizarPago 
            const redsysPagarUrl = `${window.location.protocol}//${window.location.hostname}:4040/api/pedidos/${pedidoId}/redsys/pagar`;
            const redsysURLReqParams = new URL(redsysPagarUrl);
            redsysURLReqParams.searchParams.append("id_pedido", pedidoId);
        
            try {
                const response = await axios.get(redsysURLReqParams, {
                  headers: { 'Authorization': `Bearer ${token}` }
                });
              const paymentData = response.data;
              console.log(paymentData);
             // setCart([]) LATER, AFTER SENDING CART DATA TO REDSYS
        
        
                // Construct and submit a POST form to the Redsys payment page
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = redirectUrl;
        
                // Add hidden input fields with the necessary payment response data
        
                  const inputSignVersion = document.createElement('input');
                  inputSignVersion.type = 'hidden';
                  inputSignVersion.name = 'Ds_SignatureVersion';
                  inputSignVersion.value = 'HMAC_SHA256_V1';
                  form.appendChild(inputSignVersion);
                  const inputMSParams = document.createElement('input');
                  inputMSParams.type = 'hidden';
                  inputMSParams.name = 'Ds_MerchantParameters';
                  inputMSParams.value = paymentData.merchantParameters;
                  form.appendChild(inputMSParams);
                  const inputSignature = document.createElement('input');
                  inputSignature.type = 'hidden';
                  inputSignature.name = 'Ds_Signature';
                  inputSignature.value = paymentData.signature;
                  form.appendChild(inputSignature);
                // Submit the form to the Redsys payment page
                document.body.appendChild(form);
                form.submit();
            }
        
              catch (error) {
                  if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                  } else {
                    console.log(error);
                  }
              }
          };
          return (
            <form onSubmit={handleSubmitPagar} method="POST" id="card-payment-form">
              {/* <button type="submit">Pago con tarjeta</button> */}
              <div>
                <input type="checkbox" checked={isCardSelected} id="payment-submit" name="payment-submit" value="creditcard" onChange={onchangeCreditcard} />
                <label className="payment-label" for="payment-submit">
                  <span>Pago con tarjeta</span> 
                  <span>
                    <span><img src={visa} className='img_card' alt='img card'/></span>
                    <span><img src={mastercard} className='img_card' alt='img card'/></span>
                  </span>
                </label>
              </div>
            </form>
          );
        };

    /*-----------------------PAYPAL PAYMENT----------------------*/
    const createOrder = async (data) => {

        const urlPedidoPagar = `${window.location.protocol}//${window.location.hostname}:4040/api/pedidos/${idPedido}/paypal/pagar`;
        // Order is created on the server and the order id is returned
        try {
            const response = await axios.get(urlPedidoPagar, {
              headers: { 'Authorization': `Bearer ${token}` }
            });
        console.log(response.data);
        setOrderId(response.data.orderId);
        }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
      };
      const onApprove = async (data) => {
         // Order is captured on the server and the response is returned to the browser
         const urlPedidoPagado = `${window.location.protocol}//${window.location.hostname}:4040/api/pedidos/${idPedido}/paypal/pagado`;
         try {
            const response = await axios.post(urlPedidoPagado, {
              headers: { "Content-Type": "application/json", },
              body: {orderId: orderId}
            });
        console.log(response.data)
        }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
      };
    return (
        userAddresses.length>=1 && (
      <>
        <div className='contenedor-payment'>
            {/* <div className="breadcrumb-payment">
                <span className="envio-payment">1</span><span>Envio</span><span className="guion-payment"></span><span className="envio-payment">2</span><span>Método del pago</span>
            </div> */}
            <h2 className="paso-payment">Paso 3</h2>
                   <div className="modoPago"> 
                        <h2>Paso 3 <span>Modo de pago</span></h2>
                        <p>Selecciona dentro de los métodos de pago disponibles y haz click en continuar para finalizar el pedido.  </p>
                    </div>
            <div className="payment-details">
                    <div className="payment-metod">
                        <p>Elige el método de pago:</p>
                        <div className="tarjeta-paypal">
                                    
                                    <PaymentForm /> 
                                     
                        </div> 
                        <div className="tarjeta-paypal">
                                    {/* <button className="paypal-label">Pago con Paypal</button> */}
                                   <form id="paypal-form">
                                    <div>
                                        <input type="checkbox" value="paypal" checked={isPaypalSelected} onChange={onchangePaypal} id="paypal-input"/>
                                        <label className="payment-label" htmlFor="paypal-input">Pago con Paypal</label>
                                        <span><img src={paypal} className='img_card' alt='img card'/></span>
                                      {/* <PayPalButtons
                                          createOrder={(data, actions) => createOrder(data, actions)}
                                          onApprove={(data, actions) => onApprove(data, actions)}
                                          /> */}
                                      </div>
                                   </form>
                                    
                                    
                        </div>
                    </div>
                    <ButtonLink  label='Continuar' isEnabled={isCardSelected || isPaypalSelected} type='submit' color='white' form={isCardSelected ? 'card-payment-form' : isPaypalSelected ? 'paypal-form' : null } backgroundColor='#38b44a' /> 
                </div>  
                
        </div>
        <div className="button-back">
            <div style={{marginTop:"30px"}}>
            <ButtonBack  onClick={()=>{navigate('/mi-cuenta')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525' />
            </div>
        </div>
         
      </>  )    
    );
}
export default MetodoDelPago;