import { useState, useEffect } from "react";

export const useGetCountries = () => {

  const [dataCountries, setDataCountries] = useState(null);
  const [isLoadingDataCountries, setIsLoadingDataCountries] = useState(false);
  const [errorDataCountries, setErrorDataCountries] = useState(null);
  const menuProductosUrl = `${process.env.REACT_APP_BASEURL}/paises`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingDataCountries(true);
      try {
        const response = await fetch(menuProductosUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingDataCountries(false);
        setErrorDataCountries(null);
        setDataCountries(json);
      } catch (error) {
        setErrorDataCountries(`${error} Could not Fetch Data `);
        setIsLoadingDataCountries(false);
      }
    };
    fetchData();
  }, [menuProductosUrl]);

  return { dataCountries, isLoadingDataCountries, errorDataCountries };
};
