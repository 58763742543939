import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import './Pedidos.css';
import axios from 'axios'
import {useNavigate } from "react-router-dom";
import ButtonLink from "../../../../componentes-globales/ButtonLink/ButtonLink";
import ButtonBack from "../../../../componentes-globales/ButtonBack/ButtonBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

 export const SinPedidos = () =>{
  const navigate = useNavigate();
  const handleStartShopping = () =>{
      navigate('/productos');
  }
      return(
      <div className="pedidos-empty">
            <p>Todavia no tienes ningun pedido</p>
            <ButtonLink label="Empieza a comprar" isEnabled  type='button' color='#e97625' backgroundColor='transparent' onClick={handleStartShopping} />
  </div>
  );
} 

const Pedidos = () => {

    const options = [{value: new Date()-(1000*60*60*24*90), label: 'ultimos 3 meses'},
                     {value: new Date()-(1000*60*60*24*120), label: new Date().getFullYear()}, // a revisar periodos
                     {value: new Date()-(1000*60*60*24*365*3), label: 'todos'}
                    ];
    const navigate = useNavigate();  
    const [pedidosList, setPedidosList] = useState([]) 
    const [selectedPeriod, setSelectedPeriod] = useState(options[0].value);
    const [pedidosSuccess, setPedidosSuccess] = useState(false);
    const token = sessionStorage.getItem('token');
    
    const getPedidos = async (fechaInicial) => {
        const fechaHoy = new Date().toISOString();
        
        const pedidosListBody = {
            fechaInicial: fechaInicial,
            fechaFinal: fechaHoy
        }
         try {
            const response = await axios.post(process.env.REACT_APP_BASEURL+'/pedidos/list', pedidosListBody, {
              headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` }
            } 
          );
            setPedidosSuccess(true)
          setPedidosList(response.data)
          
          }
  
          catch (error) {
              if (error.response) {
                console.log(error.response);
                console.log("server responded");
              } else {
                console.log(error);
              }
          }
    }
    
    useEffect(() => {
        getPedidos(selectedPeriod);
    },[selectedPeriod]);

    // const handleClickVolverComprar = () =>{
    //     navigate(`/metodo-de-pago/${idPedido}`);
    // }

    const handleClickDetallesPedido = (idPedido) =>{
        navigate(`/detalles-pedido/${idPedido}`);
    }
    
 return(
    
        <div className="contenedor-intranet-pedidos">
             <h2>Mis Pedidos</h2>
             <div className='filterPedidos'>
                 <p>Pedidos realizados en: </p>
                  <Select defaultValue={options[0]} options={options} name='entry-date' id='filter_date' onChange={(ev) => setSelectedPeriod(ev.value) } />

            </div>
            <div className="pedidos">
                     {pedidosList.length>0 ? pedidosList.map((pedidosItem) =>(
                                <article key={pedidosItem.id_pedido} className ="info-producto-intranet">
                                        <div className="info-pedido-intranet">
                                            <h4>Pedido Nº {pedidosItem.id_pedido} </h4> 
                                            <p>Realizado en <span>{new Date (pedidosItem.pedido_fecha).toLocaleDateString() }</span></p>
                                            <p>Total: <span>{pedidosItem.total}€ </span></p>
                                        </div>
                                        <div className="btn-detalle">
                                            <ButtonLink onClick={ev => handleClickDetallesPedido(pedidosItem.id_pedido)} label='Detalles del pedido' isEnabled type='button' color='white' backgroundColor='#38b44a' />
                                            {/* <ButtonLink label='Comprarlo de nuevo' isEnabled type='button' color='#e97625' backgroundColor='transparent'  /> */}
                                            
                                        </div>
                                </article>)): <SinPedidos /> 
                            }    
            </div>
            {/* <ButtonBack /> */}
            <div style={{marginTop:"30px"}} >
              <ButtonBack  onClick={()=>{navigate('/mi-cuenta')}} label={<span ><FontAwesomeIcon icon={faArrowLeft} /> </span>} type='button' color='#fff' backgroundColor='#EA7525' />
            </div>
        </div>
);
}

export default Pedidos;