import { useState, useEffect } from "react";

export const useFetchMenus = () => {

  const [dataMenus, setDataMenus] = useState(null);
  const [isLoadingDataMenus, setIsLoadingDataMenus] = useState(false);
  const [errorDataMenus, setErrorDataMenus] = useState(null);
  const menuProductosUrl = `${process.env.REACT_APP_BASEURL}/menu-productos-europa`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingDataMenus(true);
      try {
        const response = await fetch(menuProductosUrl);
        if (!response.ok) throw new Error(response.statusText);
        const json = await response.json();
        setIsLoadingDataMenus(false);
        setErrorDataMenus(null);
        setDataMenus(json);
      } catch (error) {
        setErrorDataMenus(`${error} Could not Fetch Data `);
        setIsLoadingDataMenus(false);
      }
    };
    fetchData();
  }, [menuProductosUrl]);

  return { dataMenus, isLoadingDataMenus, errorDataMenus };
};
