import {BrowserRouter} from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AlbaProvider } from './context/Context';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="153086240650-kpmunv7s1i8piffklbtop607oj6nfl6b.apps.googleusercontent.com">
    <PayPalScriptProvider  options={{ "client-id": "Afu5jlRiSi04fQFAOrqCfsPtdqTxrQM_0Z0eC-p78spiWYVqIyVWq_RYzaQIV5ELLITI-dLKe7TFDozR", currency:"EUR", intent:"capture" }}> 
    <AlbaProvider>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
   </AlbaProvider>
  </PayPalScriptProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
