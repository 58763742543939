import React, { useState } from 'react';

const AlbaContext = React.createContext(null);

function AlbaProvider({ children }) {

    const contactInfoInitial={
        name:'',
        surname:'',
        nif:'',
        company:'',
        address:'',
        country: 0,
        provincia:'',
        city:'',
        postalCode:'',
        phone:'',
        email:'',
    }
    
    const [language, setLanguage] = useState('es');
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [contactInfo, setContactInfo]= useState(contactInfoInitial);
    const [userAddresses, setUserAddresses] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [isGoogleClient, setIsGoogleClient] = useState(false);
    const [idPedido, setIdPedido] = useState(null);

    return (
        <AlbaContext.Provider
            value={{
                language,
                setLanguage,
                cart,
                setCart,
                contactInfo,
                setContactInfo,
                userAddresses, 
                setUserAddresses,
                total,
                setTotal,
                customer,
                setCustomer,
                isGoogleClient,
                setIsGoogleClient,
                idPedido,
                setIdPedido
            }}
        >
            {children}
        </AlbaContext.Provider>
    );
}

export { AlbaContext, AlbaProvider };
