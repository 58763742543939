import React from "react";
import './Home.css';
 import Iframe from "react-iframe";
import { useFetchProducts } from "../../componentes-globales/Hooks/useFetchProducts";
import ProductsList from "../../componentes-globales/ProductsList/ProductsList";
import { Link } from "react-router-dom";

const Home = () => { 
    const { products, errorProducts, isLoadingProducts } = useFetchProducts();
    if (isLoadingProducts) {
        return <div>Cargando...</div>
    }
    return (
        <div className='home-page'>
            <div className="sectionHome homePage">
                <h2 className="title-homepage">Naranjas de Valencia </h2>
            </div>
            <div className="productos-homepage">
                <ProductsList products= {products}/>
            </div> 
            <div className="info-hort">
                <div className="intro text-alba">
                    <div className=" text-intro">
                        <p>Bienvenidos a Hort Alba Garcés, la tienda online donde podrás encontrar las mejores y más jugosas naranjas de Valencia, cultivadas en un ambiente ecológico y sin productos químicos añadidos.</p>
                        <p>En nuestra tienda podrás comprar naranjas online de Valencia recién recogidas del árbol, con la mejor calidad, sin intermediarios y con una agricultura integral. Nos dedicamos a la venta de naranjas a particulares y grandes consumidores, y gracias a toda una vida de dedicación, conseguimos hacer llegar las mejores naranjas de Valencia a toda la península y gran parte de Europa.</p>
                        <p>¿Quieres naranjas recién recogidas del árbol? Nuestra tienda te ofrece el envío de <span className="link-to"><Link to="/naranjas">naranjas de Valencia </Link></span> en 24 horas del árbol a tu casa, casi como si las cogieras tú mismo. Además de naranjas, nuestra tienda pone a tu disposición <span className="link-to"><Link to="/mandarinas">mandarinas</Link></span>, <span className="link-to"><Link to="/limones">limones </Link></span>, <span className="link-to"><Link to="/pomelos">pomelos</Link></span>, <span className="link-to"><Link to="/calabazas">calabazas</Link></span>, <span className="link-to"><Link to="/caquis">kakis</Link></span> o <span className="link-to"><Link to="/granadas">granadas </Link></span> cultivados con el mayor de los mimos.</p>
                        <p>Todos nuestros productos incluyen IVA y transporte (península), y solamente recogemos la fruta cuando el pedido se ha realizado. Para cualquier duda le rogamos se ponga en contacto con nosotros.</p>
                    </div>
                    <picture>
                            <img src="../images/naranjas_de_valencia.jpg" alt="hort-alba" width="300" />
                    </picture>
                </div>
                <div className="iframe-homepage">
                    <Iframe width="760" height="515" src="https://www.youtube.com/embed/j7gZB8BbGJ8?si=E_6GEjbQgRNR5EYp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                </div>
                <div className="intro">
                    <h2>Naranjas a domicilio</h2>
                    <p>Pedir naranjas a domicilio mediante nuestra web tiene muchos beneficios, como saber que es un producto de calidad aún no recolectado, que se ha cultivado respetando la tradición sin añadir tratamientos químicos adicionales, que no ha estado en cámara para su maduración por lo que conserva todas sus propiedades, sabor y aroma, que sabes que vas a recibir las mejores naranjas como si las eligieras tú mismo, que están más jugosas y dulces que cualquier otras, y que mandamos las naranjas a domicilio o a donde tú nos pidas.</p>
                    <p>Además, es muy sencillo, nos haces un pedido y te mandamos las naranjas a domicilio en 24 horas para que lleguen tan frescas como si las acabaras de coger del árbol.</p>
                </div>
                <div className="intro">
                    <h2>Comprar naranjas</h2>
                    <p>En nuestra tienda podrás comprar naranjas online con las mejores garantías, pidiéndolas directamente al proveedor y sin intermediarios.</p>
                    <p>Comprar naranjas online te garantiza el producto más fresco, que no se ha mantenido en cámara y que no se han agregado productos químicos para su maduración, solamente recogemos las naranjas cuando están en su punto óptimo y nos han hecho un pedido.</p>
                    <p>Es todo muy sencillo en nuestra tienda de naranjas online, puedes comprar naranjas en unos pocos clics, nosotros las recolectamos al recibir el pago y te llegarán a casa en 24 horas, más frescas imposible.</p>
                </div>
                <div className="intro">
                    <h2>La temporada de naranjas</h2>
                    <p>La temporada de naranjas suele empezar en noviembre abarcando los siguientes meses hasta abril, donde se da por finalizada la cosecha. La naranja Navelina suele adquirirse entre noviembre y diciembre, la naranja Washington Navel, entre diciembre y enero, mientras que la naranja Navel Lane Late abarca los meses de febrero, marzo y abril. La climatología de cada año marca la maduración y la duración de las campañas de recogida de naranjas.</p>
                    <p>Nuestra empresa cuenta con una gran tradición agrícola, puesto que son ya varias generaciones dedicadas al cultivo. En Hort Alba Garcés garantizamos la venta a particulares, empresas y restauración.</p>
                    <p>Personalizamos los envíos de nuestros productos para regalos de empresa y particulares.</p>
                </div>




            </div>
        </div> 
    )
}
export default Home;
