import React from 'react';
import './Contacto.css';
import ButtonLink from '../../componentes-globales/ButtonLink/ButtonLink';


const Contacto = () =>{

        return (
            <main className="main_contacto"> 
                <div className="formulario_contacto">
                    <h2 className="tit_form">Estáremos encantados de que nos escribas</h2>
                    <form action="#" method="post" className="formularioContact" >
                        <input type="text" id="nombre" placeholder="Nombre" />
                        <input type="text" id="mail" placeholder="Email"  />
                        <input type="text" id="tlfn" placeholder="Teléfono"  />
                        <textarea id="mensaje_form" name="mensaje_form" cols="30" rows="10" placeholder="Mensaje" ></textarea>
                        {/* <input type="button" id="btn_enviar" name="enviar" value="Enviar" onClick={this.controlEnvio} /> */}
                        <span><ButtonLink  label="ENVIAR"  isEnabled type='submit' color='#fff' backgroundColor='#EA7525' onClick={()=>{}} /></span>
                    </form>
                </div>
            </main>
        );
        }
 export default Contacto;