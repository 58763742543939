import React, { useContext } from "react";
import {Link } from "react-router-dom"; 
import './Footer.css';
import { faSquareFacebook, faSquareInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlbaContext } from "../../context/Context";
import { useFetchMenusEnlacesDeInteres } from "../Hooks/useFetchMenusEnlacesDeInteres";


const Footer = ({flags, menuPrincipal,menuSecondary,menuEnlaces}) =>{
    const { language, setLanguage } = useContext(AlbaContext);
    console.log(menuEnlaces, 'dataMenusEnlaces')

    return(
    <div className="footer">
        <div className="container">
            <div className="social">
                <Link rel="nofollow" to="../"> 
                    <picture className="logo-header">
                                        <img className="hidden-sm logo-img" src={require(`../../assets/images/LOGOcut.png`)} alt='logo_img' width="177"/>
                    </picture>
                </Link>
            </div>
        <div className="productos">
            <h4>Productos</h4>
            <div className="column">
                <ul>
                {
                    menuPrincipal?.map(menuItem => (
                        <li ><Link to={language==='es' ? menuItem.url_es : menuItem.url}>{menuItem[language]}</Link></li>
                    ))
                }
                    {/* <li><Link rel="nofollow" to="/naranjas">Naranjas </Link></li>
                    <li><Link rel="nofollow" to="/mandarinas">Mandarinas </Link></li>
                    <li><Link rel="nofollow" to="../naranjas-mandarinas.php">Naranjas y Mandarinas </Link></li>
                    <li><Link rel="nofollow" to="/limones">Limones </Link></li>
                    <li><Link to="pomelos">Pomelos </Link></li> */}
                </ul>
            </div>
        </div>
        <div className="mas-producto footer-productos">
            <div className="column">
                <h4>Más Productos</h4>
                <ul>
                {
                    menuSecondary?.map(menuItem => (
                        <li ><Link to={language==='es' ? menuItem.url_es : menuItem.url}>{menuItem[language]}</Link></li>
                    ))
                }
                    {/* <li><Link to="/calabazas">Calabazas </Link></li>
                    <li><Link to="/paulownias">Paulownias </Link></li>
                    <li><Link to="/regalos">Caja Regalos </Link></li>
                    <li><Link to="/miel">Miel </Link></li> */}
                </ul>
            </div>
        </div>
        <div className="enlaces">
            <div className="column">
                <h4>Enlaces de interés</h4>
                <ul>
                {
                    menuEnlaces?.map(menuItem => (
                        <li ><Link to={menuItem?.url}>{menuItem?.vario}</Link></li>
                    ))
                }
                    {/* <li><Link rel="nofollow" to="../nosotros.php">Nosotros </Link></li>
                    <li><Link rel="nofollow" to="../supedido.php">Acerca del pedido </Link></li>
                    <li><Link rel="nofollow" to="../preguntas.php">Preguntas </Link></li>
                    <li><Link rel="nofollow" to="/contacto">Contacto </Link></li>
                    <li><Link rel="nofollow" to="../enlaces.php">Enlaces Útiles </Link></li> */}
                </ul>
            </div>
        </div>
        <div className="servicios">
            <div className="column">
                <h4>Servicios</h4>
                <ul>
                    <li><Link rel="nofollow" to="https://www.blog.hortalbagarces.com/">Blog </Link></li>
                    <li><Link rel="nofollow" to="../guia-usuario.php">Guía Usuario </Link></li>
                    <li><Link rel="nofollow" to="../wikihort.php">WikiHort </Link></li>
                    <li><Link rel="nofollow" to="../etiquetas.php">Etiquetas</Link></li>
                    <li><Link rel="nofollow" to="../galeria.php">Galería</Link></li>
                    <li><Link rel="nofollow" to="../videos.php">Videos</Link></li>
                    <li><Link rel="nofollow" to="../variedades.php">Variedades </Link></li>
                    <li><Link rel="nofollow" to="../notas-prensa.php">Notas de prensas </Link></li>
                </ul>
            </div>
        </div>
        <div className="informacion">
            <div className="column">
                <h4>Información</h4>
                <ul>
                    {/* <li><Link rel="nofollow" to="/contacto">Contacto </Link> </li> */}
                    {/* <li><Link rel="nofollow" to="../nosotros.php">Nosotros </Link></li> */}
                    <li><Link rel="nofollow" to="../mapa-web.php">Mapa Web </Link></li>
                    <li><Link rel="nofollow" to="../supedido.php">Términos y condiciones </Link></li>
                    <li><Link rel="nofollow" to="../politica-devoluciones.php">Política de devoluciones </Link> </li>
                    <li><Link rel="nofollow" to="../aviso-legal.php">Aviso Legal </Link></li>
                    <li><Link rel="nofollow" to="../preguntas.php">Preguntas </Link></li>
                    <li className="redes-footer">
                    <Link rel="nofollow" target='_blank' to="https://www.facebook.com/pages/Naranjas-Hort-Alba-Garc%C3%A9s/102986254619?fref=ts" className="btn btn-social-icon btn-facebook"> 
                        <FontAwesomeIcon icon={faSquareFacebook} style={{color: "#74C0FC",width:"25px"}}/> 
                    </Link>
                    <Link rel="nofollow" target='_blank' to="https://twitter.com/hortalbagarces" className="btn btn-social-icon btn-twitter">
                        <FontAwesomeIcon icon={faXTwitter} style={{color: "#74C0FC",width:"25px"}} /> 
                    </Link>
                    <Link rel="nofollow" target="_blank" to="https://instagram.com/hortalbagarces" className="btn-social-icon btn-instagram">
                            <FontAwesomeIcon icon={faSquareInstagram} style={{color: "#74C0FC",width:"25px"}} />
                    </Link>
                </li>
                </ul>
            </div>
        </div>
        <div className="otra-info">
            <div className="column">
                <h4>Otros idiomas</h4>
                <ul>
                    {
                        flags && flags?.map((flag) => (
                            <li role="presentation">
                                <Link rel="nofollow" to={flag.urlTo}><img src={require(`../../assets/images/flags/${flag.urlImage}`)} alt='flag'/>&nbsp;&nbsp;{flag.name} </Link>
                            </li>
                        ))
                    }
                    <li role="presentation">
                        <Link rel="nofollow" to="https://www.valenciasoranges.com"><img src={require(`../../assets/images/flags/en.png`)} alt='en_flag'/>&nbsp;&nbsp;English </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="copy-right">
            <span>Hort Alba Garcés &copy;  2024</span>
        </div>
    </div>
    </div>
    )
}

export default Footer;
  
    
